import React from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Avatar,
  Collapse,
  Chip,
} from '@mui/material';

const EnrolledUsersTable = ({ open, users = [], formatDate }) => {
  return (
    <TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box sx={{ margin: 2 }}>
            <Typography variant="h6" gutterBottom component="div">
              Enrolled Users ({users?.length || 0})
            </Typography>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Profile</TableCell>
                  <TableCell>Contact Info</TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell>Joined</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(users) && users.map((user) => {
                  console.log('Rendering user:', user);
                  return (
                    <TableRow key={user.id}>
                      <TableCell>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                          <Avatar
                            src={user.profilePictureUrl || ''}
                            alt={user.displayName || user.username}
                            sx={{ width: 40, height: 40 }}
                          />
                          <Typography variant="body2">
                            {user.displayName || user.username || 'N/A'}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {user.phoneNumber || 'N/A'}
                        </Typography>
                        {user.email && (
                          <Typography variant="body2" color="textSecondary">
                            {user.email}
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell>
                        {user.role && (
                          <Chip 
                            label={user.role} 
                            size="small"
                            color={user.role === 'Admin' ? 'primary' : 'default'}
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        {formatDate(user.createdAt)}
                      </TableCell>
                    </TableRow>
                  );
                })}
                {(!users || users.length === 0) && (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      No users enrolled yet
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};

export default EnrolledUsersTable;