// Remove fields that shouldn't be sent to the backend
export const cleanPayload = (data) => {
  const cleanData = { ...data };

  if (cleanData.contentBlocks) {
    cleanData.contentBlocks = cleanData.contentBlocks.map(block => {
      const cleanBlock = { ...block };
      // Remove validation-specific fields
      delete cleanBlock.hasValidationError;
      return cleanBlock;
    });
  }

  return cleanData;
};