import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControlLabel,
  Switch,
  Avatar,
  Typography,
} from '@mui/material';

const InitiativeForm = ({ 
  open, 
  onClose, 
  formData, 
  setFormData, 
  selectedInitiative, 
  onSubmit 
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        {selectedInitiative ? 'Edit Initiative' : 'Add New Initiative'}
      </DialogTitle>
      <form onSubmit={onSubmit}>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 1 }}>
            {selectedInitiative && selectedInitiative.imageUrl && (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Typography variant="body2">Current image:</Typography>
                <Avatar 
                  src={selectedInitiative.imageUrl}
                  alt={selectedInitiative.name}
                  variant="rounded"
                  sx={{ width: 56, height: 56 }}
                />
              </Box>
            )}

            <TextField
              label="Name"
              value={formData.name}
              onChange={(e) => setFormData({...formData, name: e.target.value})}
              required
              fullWidth
            />
            
            <TextField
              label="Description"
              value={formData.description}
              onChange={(e) => setFormData({...formData, description: e.target.value})}
              multiline
              rows={3}
              fullWidth
            />

            <TextField
              label="Button Text"
              value={formData.buttonName}
              onChange={(e) => setFormData({...formData, buttonName: e.target.value})}
              required
              fullWidth
            />

            <FormControlLabel
              control={
                <Switch
                  checked={formData.buttonDisabled}
                  onChange={(e) => setFormData({...formData, buttonDisabled: e.target.checked})}
                />
              }
              label="Disable Button"
            />

            <TextField
              type="file"
              onChange={(e) => setFormData({...formData, file: e.target.files[0]})}
              InputLabelProps={{ shrink: true }}
              helperText={selectedInitiative ? "Upload new image only if you want to replace the current one" : ""}
              fullWidth
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit" variant="contained" color="primary">
            {selectedInitiative ? 'Update' : 'Create'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default InitiativeForm;