import React, { useState } from 'react';
import {
  Box,
  TextField,
  Typography,
  Chip,
  Stack,
  Paper,
  Button,
  Collapse,
} from "@mui/material";
import SchoolSelector from "../SchoolSelector";

const CategorySelector = ({ categories, onCategoryChange }) => {
  const [showSchoolSelector, setShowSchoolSelector] = useState(false);

  const handleSchoolSelect = (udiseCode) => {
    if (udiseCode) {
      onCategoryChange([...new Set([...categories, `CATEGORY#${udiseCode}`])]);
      setShowSchoolSelector(false); // Hide the school selector after selection
    }
  };

  const handleDeleteCategory = (categoryToDelete) => {
    onCategoryChange(categories.filter(category => category !== categoryToDelete));
  };

  const handleAddCategory = (newCategory) => {
    if (newCategory.trim()) {
      onCategoryChange([...new Set([...categories, `CATEGORY#${newCategory.trim()}`])]);
    }
  };

  return (
    <Box sx={{ mb: 2 }}>
      <Typography variant="subtitle1" sx={{ mb: 1 }}>
        Categories
      </Typography>
      <Paper variant="outlined" sx={{ p: 2 }}>
        <Stack direction="row" spacing={1} flexWrap="wrap" useFlexGap sx={{ mb: 2 }}>
          {categories.map((category) => (
            <Chip
              key={category}
              label={category.replace('CATEGORY#', '')}
              onDelete={() => handleDeleteCategory(category)}
              color="primary"
              variant="outlined"
              sx={{ m: 0.5 }}
            />
          ))}
        </Stack>
        
        <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>
          <TextField
            label="Add Category"
            size="small"
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleAddCategory(e.target.value);
                e.target.value = '';
                e.preventDefault();
              }
            }}
            fullWidth
            helperText="Press Enter to add a category"
          />
          <Button 
            variant="outlined" 
            onClick={() => setShowSchoolSelector(true)}
            disabled={showSchoolSelector}
          >
            Add School
          </Button>
        </Box>

        <Collapse in={showSchoolSelector}>
          <Box sx={{ mb: 2, p: 2, bgcolor: '#f5f5f5', borderRadius: 1 }}>
            <Typography variant="subtitle2" sx={{ mb: 1 }}>
              Select School
            </Typography>
            <SchoolSelector 
              onSchoolSelect={handleSchoolSelect} 
              selectedUdiseCode={null} // Always pass null to reset selection
            />
            <Button 
              variant="text" 
              color="error" 
              onClick={() => setShowSchoolSelector(false)}
              sx={{ mt: 1 }}
            >
              Cancel
            </Button>
          </Box>
        </Collapse>
      </Paper>
    </Box>
  );
};

export default CategorySelector;