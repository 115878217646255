import React, { useState } from 'react';
import { createUser } from '../services/userService';
import { USER_ROLES } from '../constants/roles';

const CreateUser = () => {
  const [userData, setUserData] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    role: '',
    schoolId: [],
    preferredCategories: [],
    preferredTags: [],
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await createUser(userData);
      // Reset form or show success message
    } catch (error) {
      console.error('Error creating user:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <input name="name" value={userData.name} onChange={handleChange} placeholder="Name" />
      <input name="email" value={userData.email} onChange={handleChange} placeholder="Email" />
      <input name="phoneNumber" value={userData.phoneNumber} onChange={handleChange} placeholder="Phone Number" />
      <select name="role" value={userData.role} onChange={handleChange}>
        <option value="">Select Role</option>
        {Object.entries(USER_ROLES).map(([key, value]) => (
          <option key={key} value={key}>{value}</option>
        ))}
      </select>
      <button type="submit">Create User</button>
    </form>
  );
};

export default CreateUser;