import React from 'react';
import { Alert, Button, Box } from '@mui/material';

const ErrorDisplay = ({ error, onRetry }) => (
  <Box sx={{ p: 2 }}>
    <Alert 
      severity="error" 
      action={
        onRetry && (
          <Button color="inherit" size="small" onClick={onRetry}>
            Retry
          </Button>
        )
      }
    >
      {error}
    </Alert>
  </Box>
);

export default ErrorDisplay;