import {api} from './api';

export const createPost = async (formData) => {
  try {
    const response = await api.post('/aluminiapp/v2/posts', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Create post error:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const editPost = async (id, formData) => {
  try {
    const response = await api.put(`/aluminiapp/v2/posts/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Edit post error:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const getFeed = async (page = 0, limit = 10) => {
  try {
    const response = await api.get('/aluminiapp/v2/feed', {
      params: { page, limit }
    });
    return response.data;
  } catch (error) {
    console.error('Get feed error:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const deletePost = async (postId) => {
  try {
    const response = await api.delete(`/aluminiapp/v2/posts/${postId}`);
    return response.data;
  } catch (error) {
    console.error('Delete post error:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const getPostById = async (postId) => {
  try {
    const response = await api.get(`/aluminiapp/v2/posts/${postId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching post:', error);
    throw error;
  }
};