// CarouselBlock.js
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  Box,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Grid,
  IconButton,
  Paper,
} from '@mui/material';
import { Delete } from '@mui/icons-material';

const CarouselBlock = ({ block, updateBlock, onFileChange }) => {
  const addItem = () => {
    const newItem = {
      id: uuidv4(),
      type: 'image',
      url: '',
      caption: '',
    };
    updateBlock({
      ...block,
      carouselContent: {
        items: [...(block.carouselContent?.items || []), newItem],
      },
    });
  };

  const updateItem = (index, updatedItem) => {
    const updatedItems = [...(block.carouselContent?.items || [])];
    updatedItems[index] = updatedItem;
    updateBlock({
      ...block,
      carouselContent: {
        items: updatedItems,
      },
    });
  };

  const removeItem = (index) => {
    const updatedItems = [...(block.carouselContent?.items || [])];
    updatedItems.splice(index, 1);
    updateBlock({
      ...block,
      carouselContent: {
        items: updatedItems,
      },
    });
  };

  const handleFileChange = (e, index) => {
    const file = e.target.files[0];
    if (file) {
      onFileChange([file]);
      const updatedItems = [...(block.carouselContent?.items || [])];
      updatedItems[index] = {
        ...updatedItems[index],
        url: '', // Will be set by server after upload
        type: file.type.startsWith('image/') ? 'image' : 'video',
      };
      updateBlock({
        ...block,
        carouselContent: {
          items: updatedItems,
        },
      });
    }
  };

  return (
    <Box mt={2}>
      {block.carouselContent?.items?.map((item, index) => (
        <Paper key={item.id} sx={{ p: 2, mb: 2 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={2}>
              <FormControl fullWidth>
                <InputLabel>Type</InputLabel>
                <Select
                  value={item.type}
                  label="Type"
                  onChange={(e) =>
                    updateItem(index, { ...item, type: e.target.value })
                  }
                >
                  <MenuItem value="image">Image</MenuItem>
                  <MenuItem value="video">Video</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Button
                variant="contained"
                component="label"
                fullWidth
              >
                Upload {item.type}
                <input
                  type="file"
                  accept={item.type === 'image' ? 'image/*' : 'video/*'}
                  hidden
                  onChange={(e) => handleFileChange(e, index)}
                />
              </Button>
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                label={`${item.type === 'image' ? 'Image' : 'Video'} URL`}
                value={item.url}
                onChange={(e) =>
                  updateItem(index, { ...item, url: e.target.value })
                }
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={1}>
              <IconButton onClick={() => removeItem(index)}>
                <Delete />
              </IconButton>
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Caption"
                value={item.caption}
                onChange={(e) =>
                  updateItem(index, { ...item, caption: e.target.value })
                }
                fullWidth
              />
            </Grid>
          </Grid>
        </Paper>
      ))}
      <Button variant="outlined" onClick={addItem}>
        Add Carousel Item
      </Button>
    </Box>
  );
};

export default CarouselBlock;
