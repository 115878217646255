import { useState, useRef, useCallback } from 'react';
import { chatService } from '../services/chatService';
import axios from 'axios';

export const useChatGroups = () => {
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [pagination, setPagination] = useState({
    totalElements: 0,
    totalPages: 0,
    size: 10,
    page: 0,
    sort: 'updatedDate,desc'
  });
  const abortControllerRef = useRef(null);

  const cancelPreviousRequest = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    abortControllerRef.current = new AbortController();
  };

  // Memoize fetchGroups to prevent infinite rerenders
  const fetchGroups = useCallback(async (udiseCode, page = 0, size = 10, sort = 'updatedDate,desc') => {
    try {
      cancelPreviousRequest();
      setLoading(true);
      setError(null);
      
      const response = await chatService.getAllGroups(udiseCode, page, size, sort, {
        signal: abortControllerRef.current.signal
      });
      
      const processedGroups = (response.data.content || []).map(group => ({
        ...group,
        createdDate: group.createdDate || new Date().toISOString(),
        memberCount: group.memberCount || 0,
        allowChat: typeof group.allowChat === 'boolean' ? group.allowChat : true
      }));

      setGroups(processedGroups);
      setPagination({
        totalElements: response.data.totalElements,
        totalPages: response.data.totalPages,
        size: response.data.size,
        page: response.data.number,
        sort
      });

      return processedGroups;
    } catch (error) {
      if (axios.isCancel(error)) {
        return;
      }
      console.error('Error fetching groups:', error);
      setError('Failed to fetch chat groups. Please check your connection and try again.');
      setGroups([]);
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const createGroup = useCallback(async (groupData, profilePicture) => {
    try {
      setLoading(true);
      setError(null);
      const response = await chatService.createGroup(groupData, profilePicture);
      await fetchGroups(groupData.udiseCode, pagination.page, pagination.size, pagination.sort);
      return response.data;
    } catch (error) {
      console.error('Error creating group:', error);
      setError('Failed to create chat group. Please try again.');
      throw error;
    } finally {
      setLoading(false);
    }
  }, [fetchGroups, pagination]);

  const updateGroup = useCallback(async (groupId, data, profilePicture) => {
    try {
      setLoading(true);
      setError(null);
      const response = await chatService.updateGroup(groupId, data, profilePicture);
      // Note: We need udiseCode to refresh the list, it should be available in the current groups list
      const group = groups.find(g => g.id === groupId);
      if (group?.udiseCode) {
        await fetchGroups(group.udiseCode, pagination.page, pagination.size, pagination.sort);
      }
      return response.data;
    } catch (error) {
      console.error('Error updating group:', error);
      setError('Failed to update chat group. Please try again.');
      throw error;
    } finally {
      setLoading(false);
    }
  }, [fetchGroups, groups, pagination]);

  const deleteGroup = useCallback(async (groupId, udiseCode) => {
    try {
      setLoading(true);
      setError(null);
      await chatService.deleteGroup(groupId);
      await fetchGroups(udiseCode, pagination.page, pagination.size, pagination.sort);
    } catch (error) {
      console.error('Error deleting group:', error);
      setError('Failed to delete chat group. Please try again.');
      throw error;
    } finally {
      setLoading(false);
    }
  }, [fetchGroups, pagination]);

  return {
    groups,
    loading,
    error,
    pagination,
    fetchGroups,
    createGroup,
    updateGroup,
    deleteGroup,
  };
};

export default useChatGroups;