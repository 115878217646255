import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Alert,
  Typography,
} from '@mui/material';
import { ParticipationService, PARTICIPATION_TYPES } from '../../services/participationService';
import StatsOverview from '../../components/participation/StatsOverview';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import SchoolSelector from '../../components/SchoolSelector';

const ParticipationDashboard = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [stats, setStats] = useState(null);
  
  const [filters, setFilters] = useState({
    participationType: 'VOLUNTEER',
    udiseCode: 'all',
  });

  useEffect(() => {
    if (isAuthenticated) {
      fetchStats();
    }
  }, [filters, isAuthenticated]);

  const fetchStats = async () => {
    try {
      setLoading(true);
      setError(null);

      // Only include udiseCode in filters if it's not null/undefined
      const filterParams = {
        participationType: filters.participationType,
        ...(filters.udiseCode && filters.udiseCode !== 'all' && { udiseCode: filters.udiseCode }),
      };

      const statsData = await ParticipationService.getParticipationStats(
        filters.udiseCode || 'all', 
        filterParams
      );
      console.log('Stats:', statsData);
      setStats(statsData);
      
    } catch (error) {
      console.error('Error fetching stats:', error);
      if (error.response?.status === 403) {
        navigate('/login');
      } else {
        setError('Failed to load participation data. Please try again later.');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleParticipationTypeChange = (event) => {
    setFilters(prev => ({
      ...prev,
      participationType: event.target.value
    }));
  };

  const handleSchoolSelect = (udiseCode) => {
    setFilters(prev => ({
      ...prev,
      udiseCode: udiseCode || 'all'
    }));
  };

  if (!isAuthenticated) {
    return <div>Please log in to view this page.</div>;
  }

  return (
    <Container maxWidth="xl">
      <Box sx={{ py: 4 }}>
        {error && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {error}
          </Alert>
        )}
        
        {/* Header */}
        <Typography variant="h4" component="h1" gutterBottom>
          Participation Dashboard
        </Typography>
        <Typography variant="body1" color="text.secondary" paragraph>
          View and analyze participation statistics across all schools or filter by specific school
        </Typography>

        {/* Filters Section */}
        <Paper sx={{ p: 3, mb: 4 }}>
          <Grid container spacing={3} alignItems="flex-start">
            <Grid item xs={12} md={6}>
              <SchoolSelector 
                onSchoolSelect={handleSchoolSelect}
                selectedUdiseCode={filters.udiseCode !== 'all' ? filters.udiseCode : null}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel>Participation Type</InputLabel>
                <Select
                  value={filters.participationType}
                  label="Participation Type"
                  onChange={handleParticipationTypeChange}
                >
                  <MenuItem value="">All Types</MenuItem>
                  {Object.entries(PARTICIPATION_TYPES).map(([key, value]) => (
                    <MenuItem key={key} value={value}>
                      {value.replace('_', ' ')}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Paper>

        {/* Stats Overview Section */}
        <Box sx={{ mb: 4 }}>
          <StatsOverview stats={stats} loading={loading} />
        </Box>
      </Box>
    </Container>
  );
};

export default ParticipationDashboard;