import { api } from './api';

export const chatService = {
  // Group Management
  getAllGroups: (udiseCode, page = 0, size = 10, sort = 'updatedDate,desc') => 
    api.get(`/aluminiapp/v2/chat/groups/school/${udiseCode}`, {
      params: {
        page,
        size,
        ...(sort ? { sort } : {})
      }
    }),
  
  getGroup: (groupId) => 
    api.get(`/aluminiapp/v2/chat/groups/${groupId}`),
  
  createGroup: (groupData, profilePicture) => {
    const formData = new FormData();
    
    // Add the JSON data
    formData.append('data', JSON.stringify({
      name: groupData.name,
      description: groupData.description,
      udiseCode: groupData.udiseCode,
      allowChat: groupData.allowChat
    }));
    
    // Add profile picture if exists
    if (profilePicture) {
      formData.append('profilePicture', profilePicture);
    }
    
    return api.post(`/aluminiapp/v2/chat/groups`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  
  updateGroup: (groupId, groupData, profilePicture) => {
    const formData = new FormData();
    
    // Add the JSON data
    formData.append('data', JSON.stringify({
      name: groupData.name,
      description: groupData.description,
      allowChat: groupData.allowChat
    }));
    
    // Add profile picture if exists
    if (profilePicture) {
      formData.append('profilePicture', profilePicture);
    }
    
    return api.put(`/aluminiapp/v2/chat/groups/${groupId}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  
  deleteGroup: (groupId) => 
    api.delete(`/aluminiapp/v2/chat/groups/${groupId}`),

  // Member Management
  getGroupMembers: (groupId, { page = 0, size = 10, sort = 'updatedDate,desc', status = null } = {}) => {
    const params = {
      page,
      size,
      ...(sort ? { sort } : {})
    };
    if (status) {
      params.status = status;
    }
    return api.get(`/aluminiapp/v2/chat/groups/${groupId}/members`, { params });
  },
  
  addGroupMember: (groupId, userId) => 
    api.post(`/aluminiapp/v2/chat/groups/${groupId}/members`, {
      chatGroupId: groupId,
      userId: userId,
      role: 'USER',
      status: 'ACTIVE'
    }),
  
  removeGroupMember: (groupId, memberId) => 
    api.delete(`/aluminiapp/v2/chat/groups/${groupId}/members/${memberId}`),
  
  updateMemberStatus: (groupId, memberId, status) => 
    api.put(`/aluminiapp/v2/chat/groups/${groupId}/members/${memberId}/status`, { status })
};

export default chatService;