import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  Button
} from '@mui/material';
import ChatGroupMembers from './ChatGroupMembers';

const ChatMembersDialog = ({ open, onClose, group }) => {
  if (!group) return null;

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h6">
            Members - {group.name}
          </Typography>
          <Button onClick={onClose}>Close</Button>
        </Box>
      </DialogTitle>
      <DialogContent>
        <ChatGroupMembers groupId={group.id} />
      </DialogContent>
    </Dialog>
  );
};

export default ChatMembersDialog;