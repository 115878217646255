import React from 'react';

const ProfilePictureUpload = ({ preview, onFileChange }) => {
  return (
    <div className="mb-6">
      <label className="block text-gray-700 text-sm font-bold mb-2">
        Profile Picture:
      </label>
      {preview && (
        <div className="mb-2">
          <img
            src={preview}
            alt="Profile Preview"
            className="w-24 h-24 object-cover rounded-lg border-2 border-gray-200"
            style={{ width: "100px", height: "100px", objectFit: "cover" }}
          />
        </div>
      )}
      <input
        type="file"
        onChange={onFileChange}
        accept="image/*"
        className="block w-full text-sm text-gray-500
          file:mr-4 file:py-2 file:px-4
          file:rounded-full file:border-0
          file:text-sm file:font-semibold
          file:bg-blue-50 file:text-blue-700
          hover:file:bg-blue-100"
      />
    </div>
  );
};

export default ProfilePictureUpload;