import { api } from './api';

export const pollService = {
  // Get paginated list of polls
  getPolls: async (page = 0, size = 10, status = 'all') => {
    try {
      const response = await api.get('/aluminiapp/v2/admin/polls', {
        params: {
          page,
          size,
          status
        }
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // Get polls summary
  getPollsSummary: async () => {
    try {
      const response = await api.get('/aluminiapp/v2/admin/polls/summary');
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // Get detailed stats for a specific poll
  getPollDetailedStats: async (pollId) => {
    try {
      const response = await api.get(`/aluminiapp/v2/admin/polls/${pollId}/detailed-stats`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // Get aggregate statistics
  getAggregateStats: async (timeframe = 'monthly', fromDate, toDate) => {
    try {
      const response = await api.get('/aluminiapp/v2/admin/polls/stats/aggregate', {
        params: {
          timeframe,
          fromDate,
          toDate
        }
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
};

export default pollService;