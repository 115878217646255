import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { createPost } from "../services/postService";
import { useAuth } from "../contexts/AuthContext";
import ContentBlock from "../components/ContentBlocks/ContentBlock";
import CategorySelector from "../components/CategorySelector";

import {
  Box,
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";

const CreatePost = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [files, setFiles] = useState({});
  const [postData, setPostData] = useState({
    userId: user.userId,
    authorId: user.userId,
    authorName: user.name,
    authorRole: user.role,
    schoolId: user.schoolId,
    title: "",
    summary: "",
    contentType: "EVENT",
    ctaType: "",
    contentBlocks: [],
    categories: [],
    tags: [],
    targetAudience: `STATE#Karnataka`,
    visibilityLevel: "STATE",
    likeCount: 0,
    commentCount: 0,
    shareCount: 0,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPostData((prev) => ({ ...prev, [name]: value }));
  };

  const handleCategoryChange = (newCategories) => {
    setPostData(prev => ({
      ...prev,
      categories: newCategories
    }));
  };

  const handleFileChange = (blockIdentifier, newFiles) => {
    setFiles((prevFiles) => ({
      ...prevFiles,
      [blockIdentifier]: newFiles,
    }));
  };

  const updateContentBlock = (index, updatedBlock) => {
    setPostData((prev) => {
      const newBlocks = [...prev.contentBlocks];
      newBlocks[index] = updatedBlock;
      return { ...prev, contentBlocks: newBlocks };
    });
  };

  const removeContentBlock = (index) => {
    setPostData((prev) => ({
      ...prev,
      contentBlocks: prev.contentBlocks.filter((_, i) => i !== index),
    }));
  };

  const moveContentBlock = (fromIndex, toIndex) => {
    if (toIndex < 0 || toIndex >= postData.contentBlocks.length) return;
    setPostData((prev) => {
      const newBlocks = [...prev.contentBlocks];
      const [movedBlock] = newBlocks.splice(fromIndex, 1);
      newBlocks.splice(toIndex, 0, movedBlock);
      return { ...prev, contentBlocks: newBlocks };
    });
  };

  const addNewContentBlock = () => {
    setPostData((prev) => ({
      ...prev,
      contentBlocks: [
        ...prev.contentBlocks,
        {
          type: "Text",
          order: prev.contentBlocks.length + 1,
          textContent: { text: "" },
          createdDate: new Date().toISOString(),
          updatedDate: new Date().toISOString(),
        },
      ],
    }));
  };

  const validateVideoBlocks = () => {
    const errors = [];
    postData.contentBlocks.forEach((block, index) => {
      if (block.type === 'Video') {
        if (!block.videoContent?.thumbnailUrl?.trim()) {
          errors.push(`Content Block ${index + 1}: Thumbnail URL is required for video content`);
        } else if (!block.videoContent.thumbnailUrl.match(/^https?:\/\/.+/)) {
          errors.push(`Content Block ${index + 1}: Invalid thumbnail URL format`);
        }
      }
    });
    return errors;
  };

  const validateContentBlocks = () => {
    const errors = [];
    
    if (!postData.contentBlocks || postData.contentBlocks.length === 0) {
      errors.push("At least one content block is required");
      return errors;
    }

    postData.contentBlocks.forEach((block, index) => {
      // Check for empty content in each block type
      switch (block.type) {
        case 'Text':
          if (!block.textContent?.text?.trim()) {
            errors.push(`Content Block ${index + 1}: Text content cannot be empty`);
          }
          break;
        case 'Image':
          if (!files[block.order?.toString()] && !block.imageContent?.url?.trim()) {
            errors.push(`Content Block ${index + 1}: Please either upload an image or provide an image URL`);
          }
          break;
        case 'Video':
          if (!files[block.order?.toString()] && !block.videoContent?.url?.trim()) {
            errors.push(`Content Block ${index + 1}: Please either upload a video or provide a video URL`);
          }
          if (!block.videoContent?.thumbnailUrl?.trim()) {
            errors.push(`Content Block ${index + 1}: Thumbnail URL is required for video content`);
          }
          break;
        // Add other content type validations as needed
      }
    });

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setIsSubmitting(true);

    try {
      // Basic field validation
      if (!postData.title.trim()) {
        throw new Error("Title is required");
      }

      if (!postData.summary.trim()) {
        throw new Error("Summary is required");
      }

      if (!postData.contentType) {
        throw new Error("Content Type is required");
      }

      // Validate content blocks and video thumbnails
      const blockErrors = validateContentBlocks();
      const videoErrors = validateVideoBlocks();
      const allErrors = [...blockErrors, ...videoErrors];

      if (allErrors.length > 0) {
        throw new Error(allErrors.join('\n'));
      }

      let submissionData = { ...postData };

      // Clean up categories and tags
      submissionData.categories = submissionData.categories || [];
      submissionData.tags = submissionData.tags || [];

      const formData = new FormData();
      formData.append("metadata", JSON.stringify(submissionData));

      // Handle file uploads
      Object.entries(files).forEach(([blockIdentifier, blockFiles]) => {
        blockFiles.forEach((file, index) => {
          formData.append(
            'file',
            file,
            `file_${blockIdentifier}_${index}_${file.name}`
          );
        });
      });

      await createPost(formData);
      navigate("/");
    } catch (err) {
      setError(err.message || "Failed to create post. Please try again.");
      console.error("Create post error:", err);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h4" align="center" sx={{ mt: 4, mb: 2 }}>
        Create New Post
      </Typography>
      {error && (
        <Typography color="error" align="center" sx={{ mb: 2, whiteSpace: 'pre-line' }}>
          {error}
        </Typography>
      )}
      <form onSubmit={handleSubmit} noValidate>
        <Box sx={{ mb: 2 }}>
          <TextField
            label="Title"
            name="title"
            value={postData.title}
            onChange={handleInputChange}
            required
            fullWidth
            error={!postData.title.trim()}
          />
        </Box>
        <Box sx={{ mb: 2 }}>
          <TextField
            label="Summary"
            name="summary"
            value={postData.summary}
            onChange={handleInputChange}
            required
            multiline
            rows={4}
            fullWidth
            error={!postData.summary.trim()}
          />
        </Box>
        <Box sx={{ mb: 2 }}>
          <FormControl fullWidth required>
            <InputLabel id="content-type-label">Content Type</InputLabel>
            <Select
              labelId="content-type-label"
              id="content-type"
              name="contentType"
              value={postData.contentType}
              label="Content Type"
              onChange={handleInputChange}
            >
              <MenuItem value="EVENT">Event</MenuItem>
              <MenuItem value="ANNOUNCEMENT">Announcement</MenuItem>
              <MenuItem value="NEWS">News</MenuItem>
              <MenuItem value="CTA">Call to Action</MenuItem>
            </Select>
          </FormControl>
        </Box>

        {postData.contentType === "CTA" && (
          <Box sx={{ mb: 2 }}>
            <FormControl fullWidth>
              <InputLabel id="cta-type-label">CTA Type</InputLabel>
              <Select
                labelId="cta-type-label"
                id="cta-type"
                name="ctaType"
                value={postData.ctaType || ""}
                label="CTA Type"
                onChange={handleInputChange}
              >
                <MenuItem value="join_now">Join Now</MenuItem>
                <MenuItem value="attend_event">Attend Event</MenuItem>
                <MenuItem value="interested">Interested</MenuItem>
              </Select>
            </FormControl>
          </Box>
        )}

        <CategorySelector 
          categories={postData.categories} 
          onCategoryChange={handleCategoryChange}
        />

        <Box sx={{ mb: 2 }}>
          <TextField
            label="Tags (comma-separated)"
            name="tags"
            value={postData.tags.length > 0 ? postData.tags.join(", ") : ""}
            onChange={(e) => {
              const value = e.target.value;
              setPostData((prev) => ({
                ...prev,
                tags: value.split(",").map((item) => item.trim())
              }));
            }}
            fullWidth
          />
        </Box>

        <Box sx={{ mb: 2 }}>
          <Typography variant="h5" sx={{ mb: 2 }}>
            Content Blocks
          </Typography>
          {postData.contentBlocks.map((block, index) => (
            <ContentBlock
              key={index}
              block={block}
              index={index}
              updateBlock={updateContentBlock}
              removeBlock={removeContentBlock}
              moveBlock={moveContentBlock}
              onFileChange={handleFileChange}
            />
          ))}
          <Button
            variant="outlined"
            onClick={addNewContentBlock}
            sx={{ mt: 2 }}
          >
            Add New Content Block
          </Button>
        </Box>
        <Box sx={{ position: 'relative' }}>
          <Button 
            type="submit" 
            variant="contained" 
            color="primary" 
            fullWidth
            disabled={isSubmitting || !postData.title.trim() || !postData.summary.trim() || !postData.contentType || postData.contentBlocks.length === 0}
          >
            {isSubmitting ? 'Creating Post...' : 'Create Post'}
          </Button>
          {isSubmitting && (
            <CircularProgress
              size={24}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          )}
        </Box>
      </form>
    </Container>
  );
};

export default CreatePost;