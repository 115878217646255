import React, { useState, useEffect } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Button,
  Select,
  MenuItem,
  FormControl,
  CircularProgress,
  Alert,
  Typography,
  TextField,
  Stack,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider
} from '@mui/material';
import { chatService } from '../../services/chatService';
import { profileService } from '../../services/profileService';

const ChatGroupMembers = ({ groupId }) => {
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [pagination, setPagination] = useState({
    page: 0,
    size: 10,
    totalElements: 0,
    totalPages: 0
  });
  const [statusFilter, setStatusFilter] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [searchedUser, setSearchedUser] = useState(null);
  const [searchError, setSearchError] = useState(null);

  const fetchMembers = async (status = statusFilter) => {
    if (!groupId) return;
    
    try {
      setLoading(true);
      setError(null);
      
      const response = await chatService.getGroupMembers(groupId, {
        page: pagination.page,
        size: pagination.size,
        status: status || null
      });

      if (response && response.data) {
        setMembers(response.data.content);
        setPagination({
          page: response.data.number,
          size: response.data.size,
          totalElements: response.data.totalElements,
          totalPages: response.data.totalPages
        });
      } else {
        setMembers([]);
      }
    } catch (error) {
      console.error('Error fetching members:', error);
      setError(error.response?.data?.message || error.message || 'Failed to fetch members');
      setMembers([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (groupId) {
      fetchMembers();
    }
  }, [groupId, statusFilter, pagination.page, pagination.size]);

  const handleSearchUser = async () => {
    if (!phoneNumber) {
      setSearchError('Please enter a phone number');
      return;
    }
    
    try {
      setLoading(true);
      setSearchError(null);
      const response = await profileService.getUserByPhoneNumber(phoneNumber);
      
      if (response && response.data) {
        const userData = {
          ...response.data,
          userId: response.data.id
        };
        setSearchedUser(userData);
      } else {
        setSearchError('No user found with this phone number');
      }
    } catch (error) {
      console.error('Error searching user:', error);
      setSearchError(error.response?.data?.message || error.message || 'Failed to find user with this phone number');
      setSearchedUser(null);
    } finally {
      setLoading(false);
    }
  };

  const handleAddMember = async () => {
    if (!searchedUser || !groupId) return;

    try {
      setLoading(true);
      await chatService.addGroupMember(groupId, searchedUser.id);
      await fetchMembers();
      setOpenAddDialog(false);
      setPhoneNumber('');
      setSearchedUser(null);
      setError(null);
    } catch (error) {
      console.error('Error adding member:', error);
      setError('Failed to add member to group. ' + (error.response?.data?.message || error.message));
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateStatus = async (memberId, newStatus) => {
    try {
      setLoading(true);
      await chatService.updateMemberStatus(groupId, memberId, newStatus);
      await fetchMembers();
    } catch (error) {
      console.error('Error updating member status:', error);
      setError('Failed to update member status');
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveMember = async (memberId) => {
    if (!window.confirm('Are you sure you want to remove this member?')) {
      return;
    }

    try {
      setLoading(true);
      await chatService.removeGroupMember(groupId, memberId);
      await fetchMembers();
    } catch (error) {
      console.error('Error removing member:', error);
      setError('Failed to remove member');
    } finally {
      setLoading(false);
    }
  };

  const handleStatusFilterChange = (event) => {
    setStatusFilter(event.target.value);
    setPagination(prev => ({ ...prev, page: 0 }));
  };

  const handlePageChange = (event, newPage) => {
    setPagination(prev => ({ ...prev, page: newPage }));
  };

  const handleRowsPerPageChange = (event) => {
    setPagination(prev => ({
      ...prev,
      size: parseInt(event.target.value, 10),
      page: 0
    }));
  };

  if (!groupId) {
    return (
      <Alert severity="warning">
        Please select a chat group to view its members.
      </Alert>
    );
  }

  return (
    <Box>
      <Stack direction="row" spacing={2} alignItems="center" sx={{ mb: 3 }}>
        <Typography variant="h6">
          Group Members ({pagination.totalElements})
        </Typography>
        <FormControl size="small" sx={{ minWidth: 120 }}>
          <InputLabel>Filter Status</InputLabel>
          <Select
            value={statusFilter}
            label="Filter Status"
            onChange={handleStatusFilterChange}
          >
            <MenuItem value="">All</MenuItem>
            <MenuItem value="ACTIVE">Active</MenuItem>
            <MenuItem value="BLOCKED">Blocked</MenuItem>
          </Select>
        </FormControl>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpenAddDialog(true)}
        >
          Add Member
        </Button>
      </Stack>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      {loading && members.length === 0 ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
          <CircularProgress />
        </Box>
      ) : members.length === 0 ? (
        <Alert severity="info">
          No members found in this group.
        </Alert>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell><strong>User ID</strong></TableCell>
                <TableCell><strong>Member ID</strong></TableCell>
                <TableCell><strong>Role</strong></TableCell>
                <TableCell><strong>Status</strong></TableCell>
                <TableCell><strong>Joined At</strong></TableCell>
                <TableCell><strong>Actions</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {members.map((member) => (
                <TableRow key={member.id}>
                  <TableCell>{member.userId}</TableCell>
                  <TableCell>{member.id}</TableCell>
                  <TableCell>{member.role}</TableCell>
                  <TableCell>
                    <FormControl size="small" fullWidth>
                      <Select
                        value={member.status || 'ACTIVE'}
                        onChange={(e) => handleUpdateStatus(member.id, e.target.value)}
                      >
                        <MenuItem value="ACTIVE">Active</MenuItem>
                        <MenuItem value="BLOCKED">Blocked</MenuItem>
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    {member.joinedDate ? new Date(member.joinedDate).toLocaleDateString() : 'N/A'}
                  </TableCell>
                  <TableCell>
                    <Button
                      size="small"
                      color="error"
                      onClick={() => handleRemoveMember(member.id)}
                    >
                      Remove
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={pagination.totalElements}
            rowsPerPage={pagination.size}
            page={pagination.page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </TableContainer>
      )}

      {/* Add Member Dialog */}
      <Dialog open={openAddDialog} onClose={() => setOpenAddDialog(false)}>
        <DialogTitle>Add New Member</DialogTitle>
        <DialogContent>
          <Stack spacing={2} sx={{ mt: 1 }}>
            <TextField
              label="Phone Number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && handleSearchUser()}
            />
            <Button
              variant="contained"
              onClick={handleSearchUser}
              disabled={!phoneNumber || loading}
            >
              Search User
            </Button>

            {searchError && (
              <Alert severity="error" sx={{ mt: 2 }}>
                {searchError}
              </Alert>
            )}

            {searchedUser && (
              <Box sx={{ mt: 2 }}>
                <Typography variant="subtitle1" gutterBottom>User Found:</Typography>
                <Divider sx={{ my: 1 }} />
                <Stack spacing={1}>
                  <Typography><strong>Name:</strong> {searchedUser.name}</Typography>
                  <Typography><strong>Phone:</strong> {searchedUser.phoneNumber}</Typography>
                  <Typography><strong>Role:</strong> {searchedUser.role}</Typography>
                  <Typography><strong>User ID:</strong> {searchedUser.id}</Typography>
                </Stack>
              </Box>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAddDialog(false)}>Cancel</Button>
          <Button
            onClick={handleAddMember}
            disabled={!searchedUser || loading}
            variant="contained"
          >
            Add to Group
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ChatGroupMembers;