import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Switch,
  FormControlLabel,
  Button,
  Box,
  CircularProgress,
  Avatar,
} from '@mui/material';

const ChatGroupDialog = ({
  open,
  onClose,
  groupForm,
  onFormChange,
  onSubmit,
  loading,
}) => {
  const [profilePicture, setProfilePicture] = useState(null);
  const [previewUrl, setPreviewUrl] = useState('');

  const handleSwitchChange = (event) => {
    onFormChange({ ...groupForm, allowChat: event.target.checked });
  };

  const handleTextChange = (field) => (event) => {
    onFormChange({ ...groupForm, [field]: event.target.value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfilePicture(file);
      const url = URL.createObjectURL(file);
      setPreviewUrl(url);
    }
  };

  const handleSubmitWithFile = () => {
    onSubmit(profilePicture);
  };

  // Cleanup preview URL when component unmounts or dialog closes
  useEffect(() => {
    return () => {
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl);
      }
    };
  }, [previewUrl]);

  // Reset state when dialog opens/closes
  useEffect(() => {
    if (!open) {
      setProfilePicture(null);
      setPreviewUrl('');
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Create New Group</DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
          <TextField
            fullWidth
            label="Name"
            value={groupForm.name}
            onChange={handleTextChange('name')}
            required
          />
          
          <TextField
            fullWidth
            label="Description"
            value={groupForm.description}
            onChange={handleTextChange('description')}
            multiline
            rows={3}
          />
          
          <FormControlLabel
            control={
              <Switch
                checked={groupForm.allowChat}
                onChange={handleSwitchChange}
              />
            }
            label="Allow Chat"
          />

          <Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
              {previewUrl && (
                <Avatar
                  src={previewUrl}
                  alt="Profile preview"
                  sx={{ width: 56, height: 56 }}
                />
              )}
              <input
                accept="image/*"
                style={{ display: 'none' }}
                id="group-profile-pic-upload"
                type="file"
                onChange={handleFileChange}
              />
              <label htmlFor="group-profile-pic-upload">
                <Button variant="outlined" component="span">
                  {previewUrl ? 'Change Picture' : 'Upload Picture'}
                </Button>
              </label>
            </Box>
            {previewUrl && (
              <Box sx={{ mt: 2 }}>
                <img
                  src={previewUrl}
                  alt="Profile preview"
                  style={{
                    maxWidth: '200px',
                    maxHeight: '200px',
                    objectFit: 'cover',
                    borderRadius: '4px',
                  }}
                />
              </Box>
            )}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleSubmitWithFile}
          variant="contained"
          color="primary"
          disabled={loading}
          startIcon={loading ? <CircularProgress size={20} /> : null}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChatGroupDialog;