import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Typography,
  Paper,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Link,
} from '@mui/material';
import { Edit, Delete, Description, PictureAsPdf } from '@mui/icons-material';
import { getAllDocuments, createDocument, updateDocument, deleteDocument } from '../services/documentService';

const GO_Circulars = () => {
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [totalElements, setTotalElements] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    documentDate: '',
    type: 'GO',
    file: null
  });

  useEffect(() => {
    fetchDocuments();
  }, [page, rowsPerPage]);

  const fetchDocuments = async () => {
    try {
      setLoading(true);
      const data = await getAllDocuments(page, rowsPerPage);
      setDocuments(data.content || []);
      setTotalElements(data.pageable.totalElements || 0);
    } catch (err) {
      setError('Failed to fetch documents. Please try again.');
      console.error('Fetch documents error:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this document?')) {
      try {
        await deleteDocument(id);
        fetchDocuments();
      } catch (err) {
        setError('Failed to delete document. Please try again.');
        console.error('Delete document error:', err);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataObj = new FormData();
    if (formData.file) { // Only append file if a new one is selected
      formDataObj.append('file', formData.file);
    }
    formDataObj.append('metadata', JSON.stringify({
      name: formData.name,
      description: formData.description,
      documentDate: formData.documentDate,
      type: formData.type
    }));

    try {
      if (selectedDocument) {
        await updateDocument(selectedDocument.id, formDataObj);
      } else {
        await createDocument(formDataObj);
      }
      setOpenDialog(false);
      fetchDocuments();
      setError('');
    } catch (error) {
      setError('Failed to save document. Please try again.');
      console.error('Save document error:', error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredDocuments = searchTerm
    ? documents.filter((doc) =>
        doc.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        doc.description?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        doc.type.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : documents;

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  const formatFileSize = (sizeInMB) => {
    return `${sizeInMB.toFixed(2)} MB`;
  };

  if (loading && documents.length === 0) {
    return (
      <Container maxWidth="md" sx={{ textAlign: 'center', mt: 4 }}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container maxWidth="lg">
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" align="center" gutterBottom>
          GOs & Circulars
        </Typography>

        {error && (
          <Typography color="error" align="center" sx={{ mb: 2 }}>
            {error}
          </Typography>
        )}

        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <TextField
            label="Search documents"
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{ width: 300 }}
            placeholder="Search by name, description, or type"
          />
          
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setSelectedDocument(null);
              setFormData({
                name: '',
                description: '',
                documentDate: '',
                type: 'GO',
                file: null
              });
              setOpenDialog(true);
            }}
          >
            Add New Document
          </Button>
        </Box>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell><strong>Document Details</strong></TableCell>
                <TableCell><strong>Type</strong></TableCell>
                <TableCell><strong>Date</strong></TableCell>
                <TableCell><strong>Size</strong></TableCell>
                <TableCell align="right"><strong>Actions</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredDocuments.map((doc) => (
                <TableRow key={doc.id}>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <PictureAsPdf color="error" sx={{ mr: 2 }} />
                      <Box>
                        <Typography variant="subtitle2">{doc.name}</Typography>
                        <Typography variant="body2" color="textSecondary">
                          {doc.description}
                        </Typography>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell>{doc.type}</TableCell>
                  <TableCell>{formatDate(doc.documentDate)}</TableCell>
                  <TableCell>{formatFileSize(doc.documentSizeInMB)}</TableCell>
                  <TableCell align="right">
                    <IconButton
                      href={doc.documentUrl}
                      target="_blank"
                      color="primary"
                      size="small"
                    >
                      <Description />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        setSelectedDocument(doc);
                        setFormData({
                          name: doc.name,
                          description: doc.description,
                          documentDate: doc.documentDate.split('T')[0], // Format date for input
                          type: doc.type,
                          file: null
                        });
                        setOpenDialog(true);
                      }}
                      color="primary"
                      size="small"
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                      onClick={() => handleDelete(doc.id)}
                      color="error"
                      size="small"
                    >
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {filteredDocuments.length === 0 && (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    <Typography variant="body1" sx={{ py: 2 }}>
                      {loading ? 'Loading documents...' : 'No documents found'}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalElements}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Box>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth="md" fullWidth>
        <DialogTitle>
          {selectedDocument ? 'Edit Document' : 'Add New Document'}
        </DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 1 }}>
              <TextField
                label="Name"
                value={formData.name}
                onChange={(e) => setFormData({...formData, name: e.target.value})}
                required
                fullWidth
              />
              
              <TextField
                label="Description"
                value={formData.description}
                onChange={(e) => setFormData({...formData, description: e.target.value})}
                multiline
                rows={3}
                fullWidth
              />

              <TextField
                type="date"
                label="Document Date"
                value={formData.documentDate}
                onChange={(e) => setFormData({...formData, documentDate: e.target.value})}
                InputLabelProps={{ shrink: true }}
                required
                fullWidth
              />

              <FormControl fullWidth>
                <InputLabel>Type</InputLabel>
                <Select
                  value={formData.type}
                  onChange={(e) => setFormData({...formData, type: e.target.value})}
                  required
                >
                  <MenuItem value="GO">GO</MenuItem>
                  <MenuItem value="CIRCULAR">Circular</MenuItem>
                  <MenuItem value="NEWSLETTER">Newsletter</MenuItem>
                </Select>
              </FormControl>

              {selectedDocument && (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Typography variant="body2">Current document:</Typography>
                  <Link 
                    href={selectedDocument.documentUrl} 
                    target="_blank"
                    sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}
                  >
                    <PictureAsPdf fontSize="small" />
                    {selectedDocument.name}
                  </Link>
                  <Typography variant="body2" color="textSecondary">
                    ({formatFileSize(selectedDocument.documentSizeInMB)})
                  </Typography>
                </Box>
              )}

              <TextField
                type="file"
                onChange={(e) => setFormData({...formData, file: e.target.files[0]})}
                InputLabelProps={{ shrink: true }}
                required={!selectedDocument}
                fullWidth
                helperText={selectedDocument ? "Upload new file only if you want to replace the current one" : ""}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
            <Button type="submit" variant="contained" color="primary">
              {selectedDocument ? 'Update' : 'Create'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Container>
  );
};

export default GO_Circulars;