import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createSchoolEvent } from '../services/schoolEventService';
import SchoolSelector from '../components/SchoolSelector';

import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Paper,
  CircularProgress,
} from '@mui/material';

const CreateSchoolEvent = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [dateError, setDateError] = useState('');
  
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    eventDate: new Date().toISOString().split('T')[0],
    eventTime: '10:00',
    udiseCode: '',
  });

  const validateDate = (date) => {
    const selectedDate = new Date(date);
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    if (selectedDate < today) {
      setDateError('Event date must be in the future');
      return false;
    }
    setDateError('');
    return true;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    
    if (name === 'eventDate') {
      validateDate(value);
    }

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSchoolSelect = (udiseCode) => {
    setFormData((prev) => ({
      ...prev,
      udiseCode: udiseCode.toString(),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateDate(formData.eventDate)) {
      return;
    }

    if (!formData.udiseCode) {
      setError('Please select a school');
      return;
    }

    setLoading(true);
    setError('');

    try {
      await createSchoolEvent(formData);
      navigate('/school-events');
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to create event. Please try again.');
      console.error('Create event error:', err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="md">
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" align="center" gutterBottom>
          Create School Event
        </Typography>

        <Paper sx={{ p: 3 }}>
          <form onSubmit={handleSubmit}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <TextField
                label="Event Name"
                name="title"
                value={formData.title}
                onChange={handleChange}
                required
                fullWidth
                placeholder="e.g., School Annual Day 2025"
              />

              <TextField
                label="Description"
                name="description"
                value={formData.description}
                onChange={handleChange}
                required
                fullWidth
                multiline
                rows={4}
                placeholder="Describe the event details, agenda, and any special instructions..."
              />

              <Typography variant="subtitle1" sx={{ mt: 2 }}>
                Select School
              </Typography>
              <SchoolSelector 
                onSchoolSelect={handleSchoolSelect}
                selectedUdiseCode={formData.udiseCode}
              />

              <Box sx={{ display: 'flex', gap: 2 }}>
                <TextField
                  label="Event Date"
                  name="eventDate"
                  type="date"
                  value={formData.eventDate}
                  onChange={handleChange}
                  required
                  fullWidth
                  error={!!dateError}
                  helperText={dateError}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

                <TextField
                  label="Event Time"
                  name="eventTime"
                  type="time"
                  value={formData.eventTime}
                  onChange={handleChange}
                  required
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min steps
                  }}
                />
              </Box>

              {error && (
                <Typography color="error" align="center">
                  {error}
                </Typography>
              )}

              <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center' }}>
                <Button
                  type="button"
                  variant="outlined"
                  onClick={() => navigate('/school-events')}
                  disabled={loading}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={loading}
                >
                  {loading ? <CircularProgress size={24} /> : 'Create Event'}
                </Button>
              </Box>
            </Box>
          </form>
        </Paper>
      </Box>
    </Container>
  );
};

export default CreateSchoolEvent;