export const inputStyles = {
  container: "mb-4",
  label: "block text-gray-700 text-sm font-bold mb-2",
  input: "shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline",
  select: "shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline",
  error: "text-red-500 text-xs italic",
};

export const schoolCardStyles = {
  container: "border rounded-lg p-4 mb-4 bg-white shadow",
  header: "text-lg font-semibold mb-4",
  inputGroup: "mb-3",
  removeButton: "bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600",
  addButton: "bg-green-500 text-white px-3 py-1 rounded hover:bg-green-600",
};

export const formStyles = {
  container: "max-w-4xl mx-auto p-4",
  header: "text-2xl font-bold mb-6",
  submitButton: "bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600",
};