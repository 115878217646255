// UserList.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { listUsers, deleteUser, assignChatAdmin } from '../services/userService';
import _ from 'lodash';
import { api } from '../services/api';

import {
  Box,
  Button,
  CircularProgress,
  Container,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Typography,
  Paper,
  TextField,
  Avatar,
  Alert,
  Collapse,
  InputAdornment,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { 
  Edit, 
  Delete, 
  Person, 
  Search, 
  Clear, 
  MoreVert,
  SupervisorAccount,
} from '@mui/icons-material';

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchLoading, setSearchLoading] = useState(false);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [totalElements, setTotalElements] = useState(0);
  const [actionMenuAnchor, setActionMenuAnchor] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchUsers();
  }, [page, rowsPerPage]);

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const data = await listUsers(page, rowsPerPage);
      setUsers(data.content || []);
      setTotalElements(data.totalElements || 0);
    } catch (err) {
      setError('Failed to fetch users. Please try again.');
      console.error('Fetch users error:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenActionMenu = (event, user) => {
    setActionMenuAnchor(event.currentTarget);
    setSelectedUser(user);
  };

  const handleCloseActionMenu = () => {
    setActionMenuAnchor(null);
    setSelectedUser(null);
  };

  const handleEditUser = () => {
    navigate(`/edit-user/${selectedUser.id}`);
    handleCloseActionMenu();
  };

  const handleDeleteUser = async () => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      try {
        await deleteUser(selectedUser.id);
        fetchUsers();
        setSuccessMessage('User deleted successfully');
        setTimeout(() => setSuccessMessage(''), 3000);
      } catch (err) {
        setError('Failed to delete user. Please try again.');
        console.error('Delete user error:', err);
      }
    }
    handleCloseActionMenu();
  };

  const handleMakeChatAdmin = async () => {
    try {
      await assignChatAdmin(selectedUser.id);
      setSuccessMessage('User has been assigned as Chat Admin');
      setTimeout(() => setSuccessMessage(''), 3000);
    } catch (err) {
      let errorMessage = 'Failed to assign Chat Admin role.';
      
      // Handle specific error cases
      if (err.response?.status === 400) {
        const detail = err.response?.data?.detail;
        const description = err.response?.data?.description;
        
        if (detail?.includes('Multiple school connections found')) {
          errorMessage = 'This user is connected to multiple schools. Please use the edit user page to specify the school before assigning chat admin role.';
        } else {
          // Use the server's description if available, otherwise fallback to detail
          errorMessage = description || detail || errorMessage;
        }
      }
      
      setError(errorMessage);
      console.error('Assign chat admin error:', err.response?.data || err);
    }
    handleCloseActionMenu();
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    setError(''); // Clear any existing errors

    // If the search term is a 10-digit number, treat it as a phone number search
    const phoneNumberPattern = /^\d{10}$/;
    if (phoneNumberPattern.test(value)) {
      debouncedPhoneSearch(value);
    }
  };

  const searchUserByPhone = async (phoneNumber) => {
    try {
      setSearchLoading(true);
      setError('');
      const response = await api.get(`/aluminiapp/v2/profile/byphonenumber/${phoneNumber}`);
      if (response.data) {
        if (!users.some(u => u.id === response.data.id)) {
          setUsers([response.data, ...users]);
          setTotalElements(prev => prev + 1);
        }
        setSuccessMessage('User found successfully!');
        setTimeout(() => setSuccessMessage(''), 3000);
      }
    } catch (err) {
      const errorMessage = err.response?.data?.message || 'User not found';
      setError(errorMessage);
    } finally {
      setSearchLoading(false);
    }
  };

  const debouncedPhoneSearch = _.debounce(searchUserByPhone, 500);

  const handleClearSearch = () => {
    setSearchTerm('');
    setError('');
    setSuccessMessage('');
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredUsers = users.filter((user) =>
    Object.values(user).some(
      (value) =>
        value && 
        value.toString().toLowerCase().includes(searchTerm.toLowerCase()) &&
        value !== '[object Object]'
    )
  );

  if (loading && users.length === 0) {
    return (
      <Container maxWidth="md" sx={{ textAlign: 'center', mt: 4 }}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container maxWidth="lg">
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" align="center" gutterBottom>
          Users
        </Typography>

        <Box sx={{ mb: 2 }}>
          <Collapse in={!!error}>
            <Alert severity="error" sx={{ mb: error ? 2 : 0 }}>
              {error}
            </Alert>
          </Collapse>
          <Collapse in={!!successMessage}>
            <Alert severity="success" sx={{ mb: successMessage ? 2 : 0 }}>
              {successMessage}
            </Alert>
          </Collapse>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <TextField
            fullWidth
            placeholder="Search users by name, email, or phone (enter 10 digits for phone search)"
            value={searchTerm}
            onChange={handleSearchChange}
            sx={{ maxWidth: '600px' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {searchLoading ? (
                    <CircularProgress size={20} />
                  ) : searchTerm && (
                    <IconButton
                      aria-label="clear search"
                      onClick={handleClearSearch}
                      edge="end"
                    >
                      <Clear />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />
          
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate('/create-user')}
          >
            Create New User
          </Button>
        </Box>

        <TableContainer component={Paper}>
          <Table aria-label="users table">
            <TableHead>
              <TableRow>
                <TableCell><strong>User Details</strong></TableCell>
                <TableCell><strong>Contact Info</strong></TableCell>
                <TableCell><strong>Role & Status</strong></TableCell>
                <TableCell align="right"><strong>Actions</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredUsers.map((user) => (
                <TableRow key={user.id}>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Avatar 
                        src={user.profilePictureUrl} 
                        alt={user.displayName}
                        sx={{ width: 40, height: 40, mr: 2 }}
                      >
                        {!user.profilePictureUrl && <Person />}
                      </Avatar>
                      <Box>
                        <Typography variant="subtitle2">
                          {user.displayName || 'No Name'}
                        </Typography>
                        <Typography variant="caption" color="textSecondary">
                          ID: {user.id}
                        </Typography>
                      </Box>
                    </Box>
                  </TableCell>
                  
                  <TableCell>
                    <Typography variant="body2">
                      📱 {user.phoneNumber || 'N/A'}
                    </Typography>
                    <Typography variant="body2">
                      📧 {user.email || 'N/A'}
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Typography variant="body2" sx={{ mb: 0.5 }}>
                      Role: {user.role || 'N/A'}
                    </Typography>
                    <Typography variant="caption" color="textSecondary">
                      Created: {new Date(user.createdAt).toLocaleDateString()}
                    </Typography>
                  </TableCell>

                  <TableCell align="right">
                    <IconButton
                      size="small"
                      onClick={(event) => handleOpenActionMenu(event, user)}
                    >
                      <MoreVert />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={totalElements}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>

        {/* Actions Menu */}
        <Menu
          anchorEl={actionMenuAnchor}
          open={Boolean(actionMenuAnchor)}
          onClose={handleCloseActionMenu}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem onClick={handleMakeChatAdmin}>
            <ListItemIcon>
              <SupervisorAccount fontSize="small" />
            </ListItemIcon>
            <ListItemText>Make Chat Admin</ListItemText>
          </MenuItem>
          <MenuItem onClick={handleEditUser}>
            <ListItemIcon>
              <Edit fontSize="small" />
            </ListItemIcon>
            <ListItemText>Edit User</ListItemText>
          </MenuItem>
          <MenuItem onClick={handleDeleteUser}>
            <ListItemIcon>
              <Delete fontSize="small" color="error" />
            </ListItemIcon>
            <ListItemText sx={{ color: 'error.main' }}>Delete User</ListItemText>
          </MenuItem>
        </Menu>
      </Box>
    </Container>
  );
};

export default UserList;