import React from 'react';
import { inputStyles } from './styles';
import { USER_ROLES } from '../../constants/roles';

const UserBasicInfo = ({ userData, onChange }) => {
  return (
    <div>
      <h3 className="text-xl font-semibold mb-4">Basic Information</h3>
      
      <div className={inputStyles.container}>
        <label className={inputStyles.label}>Username:</label>
        <input
          className={inputStyles.input}
          name="username"
          value={userData.username || ''}
          onChange={onChange}
        />
      </div>

      <div className={inputStyles.container}>
        <label className={inputStyles.label}>Display Name:</label>
        <input
          className={inputStyles.input}
          name="displayName"
          value={userData.displayName || ''}
          onChange={onChange}
        />
      </div>

      <div className={inputStyles.container}>
        <label className={inputStyles.label}>Full Name:</label>
        <input 
          className={inputStyles.input}
          name="name" 
          value={userData.name || ''} 
          onChange={onChange} 
        />
      </div>

      <div className={inputStyles.container}>
        <label className={inputStyles.label}>Phone Number:</label>
        <input
          className={inputStyles.input}
          name="phoneNumber"
          value={userData.phoneNumber || ''}
          onChange={onChange}
        />
      </div>

      <div className={inputStyles.container}>
        <label className={inputStyles.label}>WhatsApp Number:</label>
        <input
          className={inputStyles.input}
          name="whatsappNumber"
          value={userData.whatsappNumber || ''}
          onChange={onChange}
        />
      </div>

      <div className={inputStyles.container}>
        <label className={inputStyles.label}>Email:</label>
        <input
          className={inputStyles.input}
          type="email"
          name="email"
          value={userData.email || ''}
          onChange={onChange}
        />
      </div>

      <div className={inputStyles.container}>
        <label className={inputStyles.label}>Date of Birth:</label>
        <input
          className={inputStyles.input}
          type="date"
          name="dateOfBirth"
          value={userData.dateOfBirth || ''}
          onChange={onChange}
        />
      </div>

      <div className={inputStyles.container}>
        <label className={inputStyles.label}>Gender:</label>
        <select 
          className={inputStyles.select}
          name="gender" 
          value={userData.gender || ''} 
          onChange={onChange}
        >
          <option value="">Select Gender</option>
          <option value="Male">Male</option>
          <option value="Female">Female</option>
          <option value="Other">Other</option>
        </select>
      </div>

      <div className={inputStyles.container}>
        <label className={inputStyles.label}>Role:</label>
        <select 
          className={inputStyles.select}
          name="role" 
          value={userData.role || ''} 
          onChange={onChange}
        >
          <option value="">Select Role</option>
          {Object.entries(USER_ROLES).map(([key, value]) => (
            <option key={key} value={key}>
              {value}
            </option>
          ))}
        </select>
      </div>

      <div className={inputStyles.container}>
        <label className={inputStyles.label}>Bio:</label>
        <textarea
          className={inputStyles.input}
          name="bio"
          value={userData.bio || ''}
          onChange={onChange}
          rows={4}
        />
      </div>

      <div className={inputStyles.container}>
        <label className={inputStyles.label}>Current Position:</label>
        <input
          className={inputStyles.input}
          name="currentPosition"
          value={userData.currentPosition || ''}
          onChange={onChange}
        />
      </div>

      <div className={inputStyles.container}>
        <label className={inputStyles.label}>LinkedIn Profile:</label>
        <input
          className={inputStyles.input}
          name="linkedInProfile"
          value={userData.linkedInProfile || ''}
          onChange={onChange}
        />
      </div>

      <div className={inputStyles.container}>
        <label className={inputStyles.label}>Location:</label>
        <input
          className={inputStyles.input}
          name="location"
          value={userData.location || ''}
          onChange={onChange}
        />
      </div>

      <div className={inputStyles.container}>
        <label className={inputStyles.label}>Current Location:</label>
        <input
          className={inputStyles.input}
          name="currentLocation"
          value={userData.currentLocation || ''}
          onChange={onChange}
        />
      </div>

      <div className={inputStyles.container}>
        <label className={inputStyles.label}>Country of Residence:</label>
        <input
          className={inputStyles.input}
          name="countryOfResidence"
          value={userData.countryOfResidence || ''}
          onChange={onChange}
        />
      </div>

      <div className={inputStyles.container}>
        <label className={inputStyles.label}>Sector:</label>
        <input
          className={inputStyles.input}
          name="sector"
          value={userData.sector || ''}
          onChange={onChange}
        />
      </div>

      {/* Read-only Statistics */}
      <div className="mt-6">
        <h4 className="text-lg font-semibold mb-3">Activity Statistics</h4>
        <div className="grid grid-cols-2 gap-4">
          <div className={inputStyles.container}>
            <label className={inputStyles.label}>Volunteer Activities:</label>
            <input
              className={inputStyles.input}
              value={userData.volunteerActivityCount || 0}
              readOnly
            />
          </div>

          <div className={inputStyles.container}>
            <label className={inputStyles.label}>Events Attended:</label>
            <input
              className={inputStyles.input}
              value={userData.eventsAttendedCount || 0}
              readOnly
            />
          </div>

          <div className={inputStyles.container}>
            <label className={inputStyles.label}>Mentorship Count:</label>
            <input
              className={inputStyles.input}
              value={userData.mentorshipCount || 0}
              readOnly
            />
          </div>

          <div className={inputStyles.container}>
            <label className={inputStyles.label}>Donation Amount:</label>
            <input
              className={inputStyles.input}
              value={userData.donationAmount || 0}
              readOnly
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserBasicInfo;