// FileBlock.js
import React from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';

const FileBlock = ({ block, updateBlock, onFileChange }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    updateBlock({
      ...block,
      fileContent: {
        ...block.fileContent,
        [name]: value,
      },
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      onFileChange([file]);
      updateBlock({
        ...block,
        fileContent: {
          ...block.fileContent,
          name: file.name,
          size: file.size,
          fileType: file.type,
          // Note: URL will be set by the server after upload
          url: '',
        },
      });
    }
  };

  return (
    <Box mt={2}>
      <Button variant="contained" component="label">
        Upload File
        <input type="file" hidden onChange={handleFileChange} />
      </Button>
      {block.fileContent?.name && (
        <Box mt={2}>
          <Typography variant="body1">File Name: {block.fileContent.name}</Typography>
          <Typography variant="body1">File Size: {block.fileContent.size} bytes</Typography>
          <Typography variant="body1">File Type: {block.fileContent.fileType}</Typography>
        </Box>
      )}
      <TextField
        label="File URL (set by server)"
        name="url"
        value={block.fileContent?.url || ''}
        onChange={handleChange}
        placeholder="File URL (set by server)"
        disabled
        fullWidth
        sx={{ mt: 2 }}
      />
    </Box>
  );
};

export default FileBlock;
