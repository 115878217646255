import React, { useEffect } from 'react';
import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  Typography,
  Avatar,
} from '@mui/material';
import { Edit, Delete, Groups, KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';

const InitiativeListItem = ({ 
  initiative, 
  expandedRow, 
  onExpand, 
  onEdit, 
  onDelete,
  formatDate 
}) => {
  useEffect(() => {
    // This will run when the component mounts
    // alert('InitiativeListItem mounted');
  }, []);

  const handleExpandClick = () => {
    // alert(`Expanding initiative: ${initiative.id}`);
    onExpand(initiative.id);
  };

  return (
    <TableRow>
      <TableCell>
        <IconButton
          size="small"
          onClick={handleExpandClick}
        >
          {expandedRow === initiative.id ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </IconButton>
      </TableCell>
      <TableCell>
        <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
          {initiative.imageUrl && (
            <Avatar
              src={initiative.imageUrl}
              alt={initiative.name}
              variant="rounded"
              sx={{ width: 56, height: 56, mr: 2 }}
            />
          )}
          <Box>
            <Typography variant="subtitle2">{initiative.name}</Typography>
            <Typography variant="body2" color="textSecondary">
              {initiative.description}
            </Typography>
          </Box>
        </Box>
      </TableCell>
      <TableCell>
        <Typography variant="body2">
          Button Text: {initiative.buttonName}
        </Typography>
        <Typography variant="body2" color={initiative.buttonDisabled ? "error" : "success"}>
          Status: {initiative.buttonDisabled ? "Disabled" : "Enabled"}
        </Typography>
      </TableCell>
      <TableCell>{formatDate(initiative.createdAt)}</TableCell>
      <TableCell align="right">
        <IconButton
          onClick={() => onEdit(initiative)}
          color="primary"
          size="small"
        >
          <Edit />
        </IconButton>
        <IconButton
          onClick={() => onDelete(initiative.id)}
          color="error"
          size="small"
        >
          <Delete />
        </IconButton>
        <IconButton
          onClick={handleExpandClick}
          color="primary"
          size="small"
        >
          <Groups />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default InitiativeListItem;