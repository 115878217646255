import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  CardContent,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Button as MuiButton,
  Box,
  Paper,
  Grid,
  Divider
} from '@mui/material';
import { 
  PollOutlined as PollIcon,
  CheckCircleOutline as ActiveIcon,
  HighlightOff as ExpiredIcon 
} from '@mui/icons-material';
import { pollService } from '../services/pollService';

const PollsList = () => {
  const navigate = useNavigate();
  const [polls, setPolls] = useState([]);
  const [summary, setSummary] = useState(null);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [status, setStatus] = useState('all');
  const [totalPages, setTotalPages] = useState(0);

  // ... keep existing fetch functions ...
  const fetchPolls = async () => {
    try {
      setLoading(true);
      const data = await pollService.getPolls(page, 10, status);
      setPolls(data.content);
      setTotalPages(data.totalPages);
    } catch (error) {
      console.error('Error fetching polls:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchSummary = async () => {
    try {
      const data = await pollService.getPollsSummary();
      setSummary(data);
    } catch (error) {
      console.error('Error fetching summary:', error);
    }
  };

  useEffect(() => {
    fetchPolls();
    fetchSummary();
  }, [page, status]);

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
    setPage(0);
  };

  const handlePollClick = (pollId) => {
    navigate(`/polls/${pollId}`);
  };

  if (loading && !polls.length) {
    return <div>Loading...</div>;
  }

  return (
    <Box sx={{ p: 3 }}>
      <Grid container spacing={3} alignItems="center" sx={{ mb: 4 }}>
        <Grid item xs={12} md={3}>
          <Paper 
            elevation={3}
            sx={{
              p: 2,
              bgcolor: 'primary.light',
              color: 'primary.contrastText',
              display: 'flex',
              alignItems: 'center',
              gap: 2
            }}
          >
            <PollIcon fontSize="large" />
            <Box>
              <Typography variant="overline">Total Polls</Typography>
              <Typography variant="h4">{summary?.totalPolls || 0}</Typography>
            </Box>
          </Paper>
        </Grid>
        
        <Grid item xs={12} md={3}>
          <Paper 
            elevation={3}
            sx={{
              p: 2,
              bgcolor: 'success.light',
              color: 'success.contrastText',
              display: 'flex',
              alignItems: 'center',
              gap: 2
            }}
          >
            <ActiveIcon fontSize="large" />
            <Box>
              <Typography variant="overline">Active Polls</Typography>
              <Typography variant="h4">{summary?.activePolls || 0}</Typography>
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12} md={3}>
          <Paper 
            elevation={3}
            sx={{
              p: 2,
              bgcolor: 'error.light',
              color: 'error.contrastText',
              display: 'flex',
              alignItems: 'center',
              gap: 2
            }}
          >
            <ExpiredIcon fontSize="large" />
            <Box>
              <Typography variant="overline">Expired Polls</Typography>
              <Typography variant="h4">{summary?.expiredPolls || 0}</Typography>
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControl fullWidth>
            <InputLabel id="status-select-label">Filter by status</InputLabel>
            <Select
              labelId="status-select-label"
              id="status-select"
              value={status}
              label="Filter by status"
              onChange={(e) => handleStatusChange(e)}
            >
              <MenuItem value="all">All Polls</MenuItem>
              <MenuItem value="active">Active</MenuItem>
              <MenuItem value="expired">Expired</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        {polls.map((poll) => (
          <Grid item xs={12} md={6} lg={4} key={poll.pollId}>
            <Paper 
              elevation={3}
              sx={{ 
                cursor: 'pointer',
                transition: 'all 0.3s',
                '&:hover': {
                  transform: 'translateY(-4px)',
                  boxShadow: 6
                }
              }}
              onClick={() => handlePollClick(poll.pollId)}
            >
              <Box sx={{ p: 2, borderBottom: '1px solid', borderColor: 'divider' }}>
                <Typography variant="h6" gutterBottom>
                  {poll.title}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {poll.question}
                </Typography>
              </Box>
              <Box 
                sx={{ 
                  p: 2, 
                  display: 'flex', 
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  bgcolor: 'background.default'
                }}
              >
                <Typography variant="body2">
                  Responses: {poll.totalResponses}
                </Typography>
                <Typography 
                  variant="body2"
                  sx={{
                    px: 1.5,
                    py: 0.5,
                    borderRadius: 1,
                    bgcolor: poll.expiresAt < new Date().toISOString() ? 'error.light' : 'success.light',
                    color: poll.expiresAt < new Date().toISOString() ? 'error.main' : 'success.main'
                  }}
                >
                  {poll.expiresAt < new Date().toISOString() ? 'Expired' : 'Active'}
                </Typography>
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>

      <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center', gap: 2 }}>
        <MuiButton
          variant="contained"
          color="primary"
          onClick={() => setPage(p => Math.max(0, p - 1))}
          disabled={page === 0}
        >
          Previous
        </MuiButton>
        <MuiButton
          variant="contained"
          color="primary"
          onClick={() => setPage(p => p + 1)}
          disabled={page >= totalPages - 1}
        >
          Next
        </MuiButton>
      </Box>
    </Box>
  );
};

export default PollsList;