import React, { useState, useEffect } from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Typography,
  Alert,
  TextField,
  Button,
  Stack,
  IconButton,
  Tooltip,
  Switch,
  FormGroup,
  FormControlLabel,
  Divider,
} from '@mui/material';
import { Close as CloseIcon, School as SchoolIcon } from '@mui/icons-material';
import axios from 'axios';

// Create a separate axios instance for school APIs
const schoolApi = axios.create({
  baseURL: 'https://nammaschool1.tnschools.gov.in/api/pavillion',
  headers: {
    'Content-Type': 'application/json',
  }
});

const SchoolSelector = ({ onSchoolSelect, selectedUdiseCode = null }) => {
  const [districts, setDistricts] = useState([]);
  const [blocks, setBlocks] = useState([]);
  const [schools, setSchools] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState('');
  const [selectedDistrictId, setSelectedDistrictId] = useState('');
  const [selectedBlock, setSelectedBlock] = useState('');
  const [selectedBlockId, setSelectedBlockId] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [directUdiseInput, setDirectUdiseInput] = useState('');
  const [useDirectInput, setUseDirectInput] = useState(false);

  useEffect(() => {
    fetchDistricts();
  }, []);

  useEffect(() => {
    if (selectedDistrictId) {
      fetchBlocks(selectedDistrictId);
      setSelectedBlock('');
      setSelectedBlockId('');
      setSchools([]);
    }
  }, [selectedDistrictId]);

  useEffect(() => {
    if (selectedDistrictId && selectedBlockId) {
      fetchSchools(selectedDistrictId, selectedBlockId);
    }
  }, [selectedBlockId]);

  const fetchDistricts = async () => {
    try {
      setLoading(true);
      const response = await schoolApi.post('/get-district-list');
      if (response.data.status === 'success' && Array.isArray(response.data.data)) {
        setDistricts(response.data.data);
      } else {
        throw new Error('Invalid district data format');
      }
    } catch (err) {
      setError('Failed to fetch districts');
      console.error('Fetch districts error:', err);
    } finally {
      setLoading(false);
    }
  };

  const fetchBlocks = async (districtId) => {
    try {
      setLoading(true);
      const response = await schoolApi.post('/get-block-list', {
        district_id: districtId
      });
      if (response.data.status === 'success' && Array.isArray(response.data.data)) {
        setBlocks(response.data.data);
      } else {
        throw new Error('Invalid block data format');
      }
    } catch (err) {
      setError('Failed to fetch blocks');
      console.error('Fetch blocks error:', err);
    } finally {
      setLoading(false);
    }
  };

  const fetchSchools = async (districtId, blockId) => {
    try {
      setLoading(true);
      const response = await schoolApi.post('/get-school-list', {
        district_id: districtId,
        block_id: blockId
      });
      if (response.data.status === 'success' && Array.isArray(response.data.data)) {
        setSchools(response.data.data);
      } else {
        throw new Error('Invalid school data format');
      }
    } catch (err) {
      setError('Failed to fetch schools');
      console.error('Fetch schools error:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleDistrictChange = (event) => {
    const district = districts.find(d => d.district_code === event.target.value);
    if (district) {
      setSelectedDistrict(district.district_code);
      setSelectedDistrictId(district.id);
    }
  };

  const handleBlockChange = (event) => {
    const block = blocks.find(b => b.block_id === event.target.value);
    if (block) {
      setSelectedBlock(block.block_id);
      setSelectedBlockId(block.block_id);
    }
  };

  const handleSchoolChange = (event) => {
    const udiseCode = event.target.value;
    onSchoolSelect(udiseCode);
  };

  const handleDirectUdiseSubmit = () => {
    if (directUdiseInput.trim()) {
      onSchoolSelect(directUdiseInput.trim());
    }
  };

  const handleReset = () => {
    setSelectedDistrict('');
    setSelectedDistrictId('');
    setSelectedBlock('');
    setSelectedBlockId('');
    setDirectUdiseInput('');
    onSchoolSelect(null);
  };

  const isValidUdiseCode = (code) => {
    // Add your UDISE code validation logic here
    return /^\d{11}$/.test(code);
  };

  if (loading && districts.length === 0) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {/* Mode Switch */}
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={useDirectInput}
              onChange={(e) => setUseDirectInput(e.target.checked)}
            />
          }
          label="Direct UDISE Input"
        />
      </FormGroup>

      {useDirectInput ? (
        // Direct UDISE Input Mode
        <Stack direction="row" spacing={2} alignItems="center">
          <TextField
            fullWidth
            label="UDISE Code"
            value={directUdiseInput}
            onChange={(e) => setDirectUdiseInput(e.target.value)}
            error={directUdiseInput && !isValidUdiseCode(directUdiseInput)}
            helperText={directUdiseInput && !isValidUdiseCode(directUdiseInput) ? "Invalid UDISE code" : ""}
            placeholder="Enter 11-digit UDISE code"
          />
          <Button
            variant="contained"
            onClick={handleDirectUdiseSubmit}
            disabled={!isValidUdiseCode(directUdiseInput)}
          >
            Apply
          </Button>
        </Stack>
      ) : (
        // Dropdown Selection Mode
        <>
          <FormControl fullWidth>
            <InputLabel>District</InputLabel>
            <Select
              value={selectedDistrict}
              onChange={handleDistrictChange}
              label="District"
            >
              {districts.map((district) => (
                <MenuItem key={district.id} value={district.district_code}>
                  {district.district_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth disabled={!selectedDistrict}>
            <InputLabel>Block</InputLabel>
            <Select
              value={selectedBlock}
              onChange={handleBlockChange}
              label="Block"
            >
              {blocks.map((block) => (
                <MenuItem key={block.block_id} value={block.block_id}>
                  {block.block_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth disabled={!selectedBlock}>
            <InputLabel>School</InputLabel>
            <Select
              value={selectedUdiseCode || ''}
              onChange={handleSchoolChange}
              label="School"
            >
              {schools.map((school) => (
                <MenuItem key={school.udise_code} value={school.udise_code}>
                  {school.school_name} ({school.school_type})
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </>
      )}

      {/* Reset and Current Selection Area */}
      {selectedUdiseCode && (
        <Box sx={{ mt: 2 }}>
          <Alert 
            severity="info" 
            icon={<SchoolIcon />}
            action={
              <Tooltip title="Clear Selection">
                <IconButton
                  aria-label="clear"
                  color="inherit"
                  size="small"
                  onClick={handleReset}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            }
          >
            Selected School: {selectedUdiseCode}
          </Alert>
        </Box>
      )}

      {/* Show All Schools Option */}
      <Box sx={{ mt: 2 }}>
        <Button 
          fullWidth 
          variant="outlined" 
          onClick={handleReset}
          startIcon={<SchoolIcon />}
        >
          Show All Schools
        </Button>
      </Box>

      {loading && <CircularProgress size={20} />}
    </Box>
  );
};

export default SchoolSelector;