// HighlightsList.js
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { highlightService } from '../services/highlightService';

import {
  Box,
  Button,
  CircularProgress,
  Container,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Typography,
  Paper,
  TextField,
} from '@mui/material';
import { Edit, Delete, ThumbUp, Comment, Share } from '@mui/icons-material';

const HighlightsList = () => {
  const [highlights, setHighlights] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    fetchHighlights();
  }, [page, rowsPerPage]);

  const fetchHighlights = async () => {
    try {
      setLoading(true);
      const data = await highlightService.getAllHighlights(page, rowsPerPage);
      setHighlights(data.feedItems || []);
      setTotalCount(data.totalCount || data.feedItems?.length || 0);
    } catch (err) {
      setError('Failed to fetch highlights. Please try again.');
      console.error('Fetch highlights error:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (highlightId) => {
    if (window.confirm('Are you sure you want to delete this highlight?')) {
      try {
        const id = highlightId.includes('#') ? highlightId.split('#')[1] : highlightId;
        await highlightService.deleteHighlight(id);
        fetchHighlights();
      } catch (err) {
        setError('Failed to delete highlight. Please try again.');
        console.error('Delete highlight error:', err);
      }
    }
  };

  const handleEdit = (highlightId) => {
    const id = highlightId.includes('#') ? highlightId.split('#')[1] : highlightId;
    navigate(`/edit-highlight/${id}`);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setSearchTerm('');
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setSearchTerm('');
  };

  const filteredHighlights = searchTerm
    ? highlights.filter((highlight) =>
        Object.entries(highlight).some(([key, value]) => {
          const searchableFields = ['title', 'summary', 'contentType'];
          return searchableFields.includes(key) &&
            value &&
            value.toString().toLowerCase().includes(searchTerm.toLowerCase());
        })
      )
    : highlights;

  if (loading && highlights.length === 0) {
    return (
      <Container maxWidth="md" sx={{ textAlign: 'center', mt: 4 }}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container maxWidth="lg">
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" align="center" gutterBottom>
          Highlights
        </Typography>
        
        {error && (
          <Typography color="error" align="center" sx={{ mb: 2 }}>
            {error}
          </Typography>
        )}

        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <TextField
            label="Search highlights"
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{ width: 300 }}
            placeholder="Search by title or summary"
          />
          
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to="/create-highlight"
          >
            Create New Highlight
          </Button>
        </Box>

        <TableContainer component={Paper}>
          <Table aria-label="highlights table">
            <TableHead>
              <TableRow>
                <TableCell><strong>Thumbnail</strong></TableCell>
                <TableCell><strong>Engagement</strong></TableCell>
                <TableCell align="right"><strong>Actions</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredHighlights.map((highlight) => (
                <TableRow key={highlight.id}>
                  <TableCell>
                    {highlight.thumbnailUrl && (
                      <Box
                        component="img"
                        src={highlight.thumbnailUrl}
                        alt="Highlight thumbnail"
                        sx={{
                          width: 120,
                          height: 68,
                          objectFit: 'cover',
                          borderRadius: 1
                        }}
                      />
                    )}
                  </TableCell>
                  
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <ThumbUp fontSize="small" color="action" sx={{ mr: 0.5 }} />
                        <Typography variant="body2">{highlight.likeCount}</Typography>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Comment fontSize="small" color="action" sx={{ mr: 0.5 }} />
                        <Typography variant="body2">{highlight.commentCount}</Typography>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Share fontSize="small" color="action" sx={{ mr: 0.5 }} />
                        <Typography variant="body2">{highlight.shareCount}</Typography>
                      </Box>
                    </Box>
                  </TableCell>

                  <TableCell align="right">
                    <IconButton
                      onClick={() => handleEdit(highlight.id)}
                      color="primary"
                      size="small"
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                      onClick={() => handleDelete(highlight.id)}
                      color="error"
                      size="small"
                    >
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {filteredHighlights.length === 0 && (
                <TableRow>
                  <TableCell colSpan={3} align="center">
                    <Typography variant="body1" sx={{ py: 2 }}>
                      {loading ? 'Loading highlights...' : 'No highlights found'}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Box>
    </Container>
  );
};

export default HighlightsList;