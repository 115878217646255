// src/services/highlightService.js
import {api} from './api';

export const highlightService = {
  getAllHighlights: async (page = 0, size = 10) => {
    const response = await api.get('/aluminiapp/v2/highlights/feed', {
      params: { page, size }
    });
    return response.data;
  },

  getHighlightById: async (id) => {
    const response = await api.get(`/aluminiapp/v2/highlights/${id}`);
    return response.data;
  },

  createHighlight: async (highlightData) => {
    const response = await api.post('/aluminiapp/v2/highlights', highlightData);
    return response.data;
  },

  updateHighlight: async (id, highlightData) => {
    const response = await api.put(`/aluminiapp/v2/highlights/${id}`, highlightData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  },

  deleteHighlight: async (id) => {
    const response = await api.delete(`/aluminiapp/v2/highlights/${id}`);
    return response.data;
  },
};

export default highlightService;