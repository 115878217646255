import {api} from './api';

export const login = async (username, password) => {
  try {
    const response = await api.post('/aluminiapp/v2/auth/login', { "phoneNumber": username });
    // const response = await api.post('/users/login', { username, password });
    if (response.data && response.data.accessToken) {
      localStorage.setItem('token', response.data.accessToken);
    }
    return response.data;
  } catch (error) {
    console.error('Login error:', error);
    throw error;
  }
};

export const logout = () => {
  localStorage.removeItem('token');
};

export const getCurrentUser = () => {
  const token = localStorage.getItem('token');
  const user = localStorage.getItem('user');
  if (token && user) {
    return JSON.parse(user);
  }
  return null;
};