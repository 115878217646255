// VideoBlock.js
import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Typography, CardMedia, Alert } from '@mui/material';

const VideoBlock = ({ block, updateBlock, onFileChange }) => {
  const [errors, setErrors] = useState({});
  const [isDirty, setIsDirty] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  useEffect(() => {
    // Validate on mount if there's a URL already
    if (block.videoContent?.thumbnailUrl && isFirstLoad) {
      validateThumbnailUrl(block.videoContent.thumbnailUrl);
      setIsFirstLoad(false);
    }
  }, [block.videoContent?.thumbnailUrl, isFirstLoad]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setIsDirty(true);
    
    if (name === 'thumbnailUrl') {
      validateThumbnailUrl(value);
    }

    // Update the block with new value and validation status
    updateBlock({
      ...block,
      videoContent: {
        ...block.videoContent,
        [name]: value,
      },
      hasValidationError: name === 'thumbnailUrl' ? !validateThumbnailUrl(value) : block.hasValidationError
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      onFileChange([file]);
      updateBlock({
        ...block,
        videoContent: {
          ...block.videoContent,
          url: '',
          duration: '',
          caption: block.videoContent?.caption || '',
          thumbnailUrl: block.videoContent?.thumbnailUrl || '',
        },
        hasValidationError: !validateThumbnailUrl(block.videoContent?.thumbnailUrl || '')
      });
    }
  };

  const validateThumbnailUrl = (value) => {
    let newErrors = {};
    
    if (!value?.trim()) {
      newErrors.thumbnailUrl = 'Thumbnail URL is required for video content';
    } else if (!value.match(/^https?:\/\/.+/)) {
      newErrors.thumbnailUrl = 'Please enter a valid URL starting with http:// or https://';
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    if (name === 'thumbnailUrl') {
      const isValid = validateThumbnailUrl(value);
      updateBlock({
        ...block,
        hasValidationError: !isValid
      });
    }
  };

  return (
    <Box mt={2}>
      <Button variant="contained" component="label">
        Upload Video
        <input type="file" accept="video/*" hidden onChange={handleFileChange} />
      </Button>
      
      <TextField
        label="Video URL"
        name="url"
        value={block.videoContent?.url || ''}
        onChange={handleChange}
        placeholder="Video URL"
        fullWidth
        sx={{ mt: 2 }}
      />
      
      <TextField
        label="Thumbnail URL"
        name="thumbnailUrl"
        value={block.videoContent?.thumbnailUrl || ''}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder="Thumbnail URL (Required)"
        fullWidth
        required
        error={!!errors.thumbnailUrl}
        helperText={errors.thumbnailUrl}
        sx={{ mt: 2 }}
      />
      
      {(isDirty || !isFirstLoad) && errors.thumbnailUrl && (
        <Alert severity="error" sx={{ mt: 1 }}>
          {errors.thumbnailUrl}
        </Alert>
      )}

      <TextField
        label="Duration"
        name="duration"
        value={block.videoContent?.duration || ''}
        onChange={handleChange}
        placeholder="Duration"
        fullWidth
        sx={{ mt: 2 }}
      />
      
      <TextField
        label="Caption"
        name="caption"
        value={block.videoContent?.caption || ''}
        onChange={handleChange}
        placeholder="Caption"
        fullWidth
        sx={{ mt: 2 }}
      />
      
      {block.videoContent?.thumbnailUrl && !errors.thumbnailUrl && (
        <Box mt={2}>
          <Typography variant="subtitle1">Thumbnail Preview:</Typography>
          <CardMedia
            component="img"
            image={block.videoContent.thumbnailUrl}
            alt="Thumbnail Preview"
            sx={{ maxWidth: 200 }}
          />
        </Box>
      )}
    </Box>
  );
};

export default VideoBlock;