import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  FormControlLabel,
  Switch,
  Box,
  CircularProgress,
  Avatar,
} from '@mui/material';
import { chatService } from '../../services/chatService';

const EditGroupDialog = ({ open, onClose, group, onSuccess }) => {
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    allowChat: true,
  });
  const [profilePicture, setProfilePicture] = useState(null);
  const [previewUrl, setPreviewUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (group) {
      setFormData({
        name: group.name || '',
        description: group.description || '',
        allowChat: group.allowChat ?? true,
      });
      // Set the profile picture URL from the group data
      if (group.profilePictureUrl) {
        setPreviewUrl(group.profilePictureUrl);
      } else {
        setPreviewUrl('');
      }
    }
  }, [group]);

  const handleInputChange = (e) => {
    const { name, value, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: name === 'allowChat' ? checked : value
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfilePicture(file);
      // Create preview URL for the new file
      const url = URL.createObjectURL(file);
      setPreviewUrl(url);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      await chatService.updateGroup(group.id, formData, profilePicture);
      onSuccess?.();
      onClose();
    } catch (err) {
      setError(err.message || 'Failed to update group');
    } finally {
      setLoading(false);
    }
  };

  // Cleanup preview URL when component unmounts or dialog closes
  useEffect(() => {
    return () => {
      // Only revoke if it's a blob URL (created by us) and not the server URL
      if (previewUrl && previewUrl.startsWith('blob:')) {
        URL.revokeObjectURL(previewUrl);
      }
    };
  }, [previewUrl]);

  // Reset state when dialog closes
  useEffect(() => {
    if (!open) {
      setProfilePicture(null);
      // Don't reset previewUrl here as it will be updated by the group useEffect
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Edit Group</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <TextField
              name="name"
              label="Group Name"
              value={formData.name}
              onChange={handleInputChange}
              required
              fullWidth
            />
            
            <TextField
              name="description"
              label="Description"
              value={formData.description}
              onChange={handleInputChange}
              multiline
              rows={3}
              fullWidth
            />

            <FormControlLabel
              control={
                <Switch
                  name="allowChat"
                  checked={formData.allowChat}
                  onChange={handleInputChange}
                />
              }
              label="Allow Chat"
            />

            <Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                {previewUrl && (
                  <Avatar
                    src={previewUrl}
                    alt="Profile preview"
                    sx={{ width: 56, height: 56 }}
                  />
                )}
                <input
                  accept="image/*"
                  style={{ display: 'none' }}
                  id="profile-pic-upload"
                  type="file"
                  onChange={handleFileChange}
                />
                <label htmlFor="profile-pic-upload">
                  <Button variant="outlined" component="span">
                    {previewUrl ? 'Change Picture' : 'Upload Picture'}
                  </Button>
                </label>
              </Box>
              {previewUrl && (
                <Box sx={{ mt: 2 }}>
                  <img
                    src={previewUrl}
                    alt="Profile preview"
                    style={{
                      maxWidth: '200px',
                      maxHeight: '200px',
                      objectFit: 'cover',
                      borderRadius: '4px',
                    }}
                  />
                </Box>
              )}
            </Box>

            {error && (
              <Box sx={{ color: 'error.main' }}>
                {error}
              </Box>
            )}
          </Box>
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            type="submit"
            variant="contained"
            disabled={loading}
            startIcon={loading ? <CircularProgress size={20} /> : null}
          >
            Save Changes
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditGroupDialog;