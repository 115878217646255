import { api } from './api';

export const getAllInitiatives = async (page = 0, limit = 10) => {
  try {
    const response = await api.get('/aluminiapp/v2/sedInitiative', {
      params: { page, limit }
    });
    return response.data;
  } catch (error) {
    console.error('Get initiatives error:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const createInitiative = async (formData) => {
  try {
    const response = await api.post('/aluminiapp/v2/sedInitiative', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Create initiative error:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const updateInitiative = async (id, formData) => {
  try {
    const response = await api.put(`/aluminiapp/v2/sedInitiative/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Update initiative error:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const deleteInitiative = async (id) => {
  try {
    const response = await api.delete(`/aluminiapp/v2/sedInitiative/${id}`);
    return response.data;
  } catch (error) {
    console.error('Delete initiative error:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const getInitiativeById = async (id) => {
  try {
    const response = await api.get(`/aluminiapp/v2/sedInitiative/${id}`);
    return response.data;
  } catch (error) {
    console.error('Get initiative error:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const getEnrolledUsers = async (initiativeId) => {
  try {
    const response = await api.get(`/aluminiapp/v2/sedInitiativeEnrollments/initiatives/${initiativeId}/enrolledUsers`);
    return response.data;
  } catch (error) {
    console.error('Get enrollments error:', error.response ? error.response.data : error.message);
    throw error;
  }
};