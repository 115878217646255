import { api } from './api';

export const createSchoolEvent = async (eventData) => {
  try {
    const response = await api.post('/aluminiapp/v2/schoolevents', {
      eventName: eventData.title,
      description: eventData.description,
      eventDate: eventData.eventDate,
      eventTime: eventData.eventTime.substring(0, 5),
      udiseCode: eventData.udiseCode,
    });
    return response.data;
  } catch (error) {
    console.error('Create school event error:', error.response?.data || error.message);
    throw error;
  }
};

export const updateEvent = async (eventId, eventData) => {
  try {
    const response = await api.put(`/aluminiapp/v2/schoolevents/${eventId}`, {
      eventName: eventData.title,
      description: eventData.description,
      eventDate: eventData.eventDate,
      eventTime: eventData.eventTime.substring(0, 5),
      udiseCode: eventData.udiseCode,
    });
    return response.data;
  } catch (error) {
    console.error('Update event error:', error.response?.data || error.message);
    throw error;
  }
};

export const getEventById = async (eventId) => {
  try {
    const response = await api.get(`/aluminiapp/v2/schoolevents/${eventId}`);
    return response.data;
  } catch (error) {
    console.error('Get event error:', error.response?.data || error.message);
    throw error;
  }
};

export const getEventsByMonth = async (year, month, page = 0, size = 10) => {
  try {
    const response = await api.get(`/aluminiapp/v2/schoolevents/${year}/${month}`, {
      params: { page, size }
    });
    return response.data;
  } catch (error) {
    console.error('Get events error:', error.response?.data || error.message);
    throw error;
  }
};

export const getEventsBySchool = async (udiseCode, page = 0, size = 10) => {
  try {
    const response = await api.get(`/aluminiapp/v2/schoolevents/school/${udiseCode}`, {
      params: { page, size }
    });
    return response.data;
  } catch (error) {
    console.error('Get school events error:', error.response?.data || error.message);
    throw error;
  }
};

export const deleteEvent = async (eventId) => {
  try {
    await api.delete(`/aluminiapp/v2/schoolevents/${eventId}`);
  } catch (error) {
    console.error('Delete event error:', error.response?.data || error.message);
    throw error;
  }
};

export const getEventRSVPStats = async (eventId) => {
  try {
    const response = await api.get(`/aluminiapp/v2/schoolevents/${eventId}/rsvp/stats`);
    return response.data;
  } catch (error) {
    console.error('Get RSVP stats error:', error.response?.data || error.message);
    throw error;
  }
};

export const markEventInterest = async (eventId) => {
  try {
    const response = await api.post(`/aluminiapp/v2/schoolevents/${eventId}/rsvp`);
    return response.data;
  } catch (error) {
    console.error('Mark RSVP error:', error.response?.data || error.message);
    throw error;
  }
};

export const removeEventInterest = async (eventId) => {
  try {
    const response = await api.delete(`/aluminiapp/v2/schoolevents/${eventId}/rsvp`);
    return response.data;
  } catch (error) {
    console.error('Remove RSVP error:', error.response?.data || error.message);
    throw error;
  }
};

export const getUserRSVPEvents = async (page = 0, size = 10) => {
  try {
    const response = await api.get('/aluminiapp/v2/schoolevents/user/rsvp', {
      params: { page, size }
    });
    return response.data;
  } catch (error) {
    console.error('Get user RSVP events error:', error.response?.data || error.message);
    throw error;
  }
};