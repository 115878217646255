import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Typography,
  Button,
  Container,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { getCurrentUser } from '../../services/authService';
import { profileService } from '../../services/profileService';
import ChatGroupList from '../../components/chat/ChatGroupList';
import ChatGroupDialog from '../../components/chat/ChatGroupDialog';
import ChatMembersDialog from '../../components/chat/ChatMembersDialog';
import EditGroupDialog from '../../components/chat/EditGroupDialog';
import ErrorDisplay from '../../components/common/ErrorDisplay';
import useChatGroups from '../../hooks/useChatGroups';
import axios from 'axios';

const ChatAdminDashboard = () => {
  const user = getCurrentUser();
  const { 
    groups, 
    loading, 
    error: groupsError, 
    pagination,
    fetchGroups, 
    createGroup, 
    deleteGroup 
  } = useChatGroups();

  const [selectedGroup, setSelectedGroup] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openMembersDialog, setOpenMembersDialog] = useState(false);
  const [selectedGroupForMembers, setSelectedGroupForMembers] = useState(null);
  const [schools, setSchools] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState('');
  const [error, setError] = useState(null);
  const [groupForm, setGroupForm] = useState({
    name: '',
    description: '',
    allowChat: true,
    udiseCode: ''
  });

  // Fetch user's schools
  useEffect(() => {
    let mounted = true;
    const controller = new AbortController();
    
    const fetchSchools = async () => {
      try {
        const response = await profileService.getUserSchools({ signal: controller.signal });
        if (mounted) {
          setSchools(response.data);
          if (response.data.length === 1) {
            setSelectedSchool(response.data[0].udiseCode);
          }
        }
      } catch (error) {
        if (mounted && !axios.isCancel(error)) {
          console.error('Error fetching schools:', error);
          setError('Failed to fetch schools information. Please check your connection.');
        }
      }
    };

    fetchSchools();
    
    return () => {
      mounted = false;
      controller.abort();
    };
  }, []);

  const fetchGroupsIfNeeded = useCallback(async (schoolId) => {
    if (schoolId) {
      try {
        await fetchGroups(schoolId, pagination.page, pagination.size, pagination.sort);
      } catch (error) {
        if (!axios.isCancel(error)) {
          setError('Unable to load chat groups. Please check your connection.');
        }
      }
    }
  }, [fetchGroups, pagination.page, pagination.size, pagination.sort]);

  // Fetch groups when school changes
  useEffect(() => {
    let mounted = true;

    if (mounted && selectedSchool) {
      fetchGroupsIfNeeded(selectedSchool);
    }

    return () => {
      mounted = false;
    };
  }, [selectedSchool, fetchGroupsIfNeeded]);

  const handleCreateGroup = async (profilePicture) => {
    try {
      const groupData = {
        ...groupForm,
        udiseCode: selectedSchool
      };
      
      await createGroup(groupData, profilePicture);
      setOpenDialog(false);
      resetForm();
    } catch (error) {
      setError(error.message);
    }
  };

  const handleDeleteGroup = async (groupId) => {
    if (!window.confirm('Are you sure you want to delete this group?')) {
      return;
    }

    try {
      await deleteGroup(groupId, selectedSchool);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleViewMembers = (group) => {
    setSelectedGroupForMembers(group);
    setOpenMembersDialog(true);
  };

  const handleEditGroup = (group) => {
    setSelectedGroup(group);
    setOpenEditDialog(true);
  };

  const handleEditSuccess = () => {
    fetchGroupsIfNeeded(selectedSchool);
  };

  const handlePageChange = (event, newPage) => {
    fetchGroups(selectedSchool, newPage, pagination.size, pagination.sort);
  };

  const handleRowsPerPageChange = (event) => {
    const newSize = parseInt(event.target.value, 10);
    fetchGroups(selectedSchool, 0, newSize, pagination.sort);
  };

  const resetForm = () => {
    setGroupForm({
      name: '',
      description: '',
      allowChat: true,
      udiseCode: selectedSchool,
    });
    setSelectedGroup(null);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    resetForm();
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    setSelectedGroup(null);
  };

  const handleCloseMembersDialog = () => {
    setOpenMembersDialog(false);
    setSelectedGroupForMembers(null);
  };

  const handleSchoolChange = (event) => {
    setSelectedSchool(event.target.value);
  };

  const handleRetry = () => {
    setError(null);
    if (selectedSchool) {
      fetchGroupsIfNeeded(selectedSchool);
    }
  };

  if (!user) {
    return (
      <Box sx={{ p: 3 }}>
        <ErrorDisplay error="Please login to access the chat administration dashboard." />
      </Box>
    );
  }

  const currentSchool = schools.find(school => school.udiseCode === selectedSchool);

  return (
    <Container maxWidth="lg">
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" gutterBottom>
          Chat Administration Dashboard
        </Typography>
        
        {(error || groupsError) && (
          <ErrorDisplay 
            error={error || groupsError} 
            onRetry={handleRetry}
          />
        )}

        <FormControl fullWidth sx={{ mb: 3 }}>
          <InputLabel>Select School</InputLabel>
          <Select
            value={selectedSchool}
            label="Select School"
            onChange={handleSchoolChange}
          >
            {schools.map((school) => (
              <MenuItem key={school.udiseCode} value={school.udiseCode}>
                {school.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {currentSchool && (
          <Typography variant="subtitle1" sx={{ mb: 2 }}>
            Selected School: {currentSchool.name}
          </Typography>
        )}

        <Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenDialog(true)}
              disabled={!selectedSchool}
            >
              Create New Group
            </Button>
          </Box>

          <ChatGroupList
            groups={groups}
            page={pagination.page}
            rowsPerPage={pagination.size}
            loading={loading}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            onEditGroup={handleEditGroup}
            onDeleteGroup={handleDeleteGroup}
            onViewMembers={handleViewMembers}
            totalElements={pagination.totalElements}
          />
        </Box>

        <ChatGroupDialog
          open={openDialog}
          onClose={handleCloseDialog}
          groupForm={groupForm}
          onFormChange={setGroupForm}
          onSubmit={handleCreateGroup}
          loading={loading}
        />

        <EditGroupDialog
          open={openEditDialog}
          onClose={handleCloseEditDialog}
          group={selectedGroup}
          onSuccess={handleEditSuccess}
        />

        <ChatMembersDialog
          open={openMembersDialog}
          onClose={handleCloseMembersDialog}
          group={selectedGroupForMembers}
        />
      </Box>
    </Container>
  );
};

export default ChatAdminDashboard;