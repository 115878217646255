import React from 'react';
import SchoolSelector from '../SchoolSelector';

const UserSchoolInfo = ({ schools, onSchoolChange, onAddSchool, onRemoveSchool }) => {
  const handleSchoolSelect = (index) => (udiseCode) => {
    onSchoolChange(index, {
      target: {
        name: "udiseCode",
        value: udiseCode || ""
      }
    });
  };

  return (
    <div>
      <h3>School Information</h3>
      {schools.map((school, index) => (
        <div
          key={index}
          style={{
            border: "1px solid #ccc",
            padding: "10px",
            marginBottom: "10px",
          }}
        >
          <h4>School #{index + 1}</h4>

          <div style={{ marginBottom: "15px" }}>
            <SchoolSelector
              onSchoolSelect={handleSchoolSelect(index)}
              selectedUdiseCode={school.udiseCode}
            />
          </div>

          <div style={{ marginBottom: "5px" }}>
            <label>Class of Joining: </label>
            <input
              type="text"
              name="classOfJoining"
              value={school.classOfJoining || ""}
              onChange={(e) => onSchoolChange(index, e)}
            />
          </div>

          <div style={{ marginBottom: "5px" }}>
            <label>Class of Passing: </label>
            <input
              type="text"
              name="classOfPassing"
              value={school.classOfPassing || ""}
              onChange={(e) => onSchoolChange(index, e)}
            />
          </div>

          <div style={{ marginBottom: "5px" }}>
            <label>Favorite Teacher: </label>
            <input
              type="text"
              name="favoriteTeacher"
              value={school.favoriteTeacher || ""}
              onChange={(e) => onSchoolChange(index, e)}
            />
          </div>

          <div style={{ marginBottom: "5px" }}>
            <label>Teacher Contact Details: </label>
            <input
              type="text"
              name="teacherContactDetails"
              value={school.teacherContactDetails || ""}
              onChange={(e) => onSchoolChange(index, e)}
            />
          </div>

          <div style={{ marginBottom: "5px" }}>
            <label>Willing to Connect with School?: </label>
            <input
              type="checkbox"
              name="willingToConnectWithSchool"
              checked={school.willingToConnectWithSchool || false}
              onChange={(e) => onSchoolChange(index, e)}
            />
          </div>

          <div style={{ marginBottom: "5px" }}>
            <label>Is Connected to School?: </label>
            <input
              type="checkbox"
              name="isConnectedToSchool"
              checked={school.isConnectedToSchool || false}
              onChange={(e) => onSchoolChange(index, e)}
            />
          </div>

          <button
            type="button"
            onClick={() => onRemoveSchool(index)}
            style={{ marginTop: "5px" }}
          >
            Remove This School
          </button>
        </div>
      ))}

      <button type="button" onClick={onAddSchool}>
        + Add School
      </button>
    </div>
  );
};

export default UserSchoolInfo;