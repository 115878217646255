import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Typography,
  Paper,
  TextField,
} from '@mui/material';
import { getAllInitiatives, createInitiative, updateInitiative, deleteInitiative, getEnrolledUsers } from '../../services/initiativeService';
import InitiativeForm from './InitiativeForm';
import EnrolledUsersTable from './EnrolledUsersTable';
import InitiativeListItem from './InitiativeListItem';

const SED_Initiatives = () => {
  const [initiatives, setInitiatives] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [totalElements, setTotalElements] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedInitiative, setSelectedInitiative] = useState(null);
  const [expandedRow, setExpandedRow] = useState(null);
  const [enrolledUsers, setEnrolledUsers] = useState([]);
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    buttonName: 'Enroll Now',
    buttonDisabled: false,
    file: null
  });

  useEffect(() => {
    fetchInitiatives();
  }, [page, rowsPerPage]);

  const fetchInitiatives = async () => {
    try {
      setLoading(true);
      const data = await getAllInitiatives(page, rowsPerPage);
      setInitiatives(data.initiatives || []);
      setTotalElements(data.totalItems || 0);
    } catch (err) {
      setError('Failed to fetch initiatives. Please try again.');
      console.error('Fetch initiatives error:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this initiative?')) {
      try {
        await deleteInitiative(id);
        fetchInitiatives();
      } catch (err) {
        setError('Failed to delete initiative. Please try again.');
        console.error('Delete initiative error:', err);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataObj = new FormData();
    if (formData.file) {
      formDataObj.append('file', formData.file);
    }
    formDataObj.append('metadata', JSON.stringify({
      name: formData.name,
      description: formData.description,
      buttonName: formData.buttonName,
      buttonDisabled: formData.buttonDisabled,
      isPublished: 'Y'
    }));

    try {
      if (selectedInitiative) {
        await updateInitiative(selectedInitiative.id, formDataObj);
      } else {
        await createInitiative(formDataObj);
      }
      setOpenDialog(false);
      fetchInitiatives();
      setError('');
    } catch (error) {
      setError('Failed to save initiative. Please try again.');
      console.error('Save initiative error:', error);
    }
  };

  const handleRowExpand = async (initiativeId) => {
      if (expandedRow === initiativeId) {
        setExpandedRow(null);
        setEnrolledUsers([]);
      } else {
        const users = await getEnrolledUsers(initiativeId);
        setEnrolledUsers(users || []);
        setExpandedRow(initiativeId);
      }
  };

  const handleEdit = (initiative) => {
    setSelectedInitiative(initiative);
    setFormData({
      name: initiative.name,
      description: initiative.description,
      buttonName: initiative.buttonName,
      buttonDisabled: initiative.buttonDisabled,
      file: null
    });
    setOpenDialog(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredInitiatives = searchTerm
    ? initiatives.filter((initiative) =>
        initiative.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        initiative.description?.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : initiatives;

  const formatDate = (dateString) => {
    if (!dateString) return '-';
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  if (loading && initiatives.length === 0) {
    return (
      <Container maxWidth="md" sx={{ textAlign: 'center', mt: 4 }}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container maxWidth="lg">
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" align="center" gutterBottom>
          SED Initiatives
        </Typography>

        {error && (
          <Typography color="error" align="center" sx={{ mb: 2 }}>
            {error}
          </Typography>
        )}

        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <TextField
            label="Search initiatives"
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{ width: 300 }}
            placeholder="Search by name or description"
          />
          
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setSelectedInitiative(null);
              setFormData({
                name: '',
                description: '',
                buttonName: 'Enroll Now',
                buttonDisabled: false,
                file: null
              });
              setOpenDialog(true);
            }}
          >
            Add New Initiative
          </Button>
        </Box>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell><strong>Initiative Details</strong></TableCell>
                <TableCell><strong>Button Status</strong></TableCell>
                <TableCell><strong>Created</strong></TableCell>
                <TableCell align="right"><strong>Actions</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredInitiatives.map((initiative) => (
                <React.Fragment key={initiative.id}>
                  <InitiativeListItem
                    initiative={initiative}
                    expandedRow={expandedRow}
                    onExpand={handleRowExpand}
                    onEdit={handleEdit}
                    onDelete={handleDelete}
                    formatDate={formatDate}
                  />
                  <EnrolledUsersTable
                    open={expandedRow === initiative.id}
                    users={enrolledUsers}
                    formatDate={formatDate}
                  />
                </React.Fragment>
              ))}
              {filteredInitiatives.length === 0 && (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    <Typography variant="body1" sx={{ py: 2 }}>
                      {loading ? 'Loading initiatives...' : 'No initiatives found'}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalElements}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Box>

      <InitiativeForm
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        formData={formData}
        setFormData={setFormData}
        selectedInitiative={selectedInitiative}
        onSubmit={handleSubmit}
      />
    </Container>
  );
};

export default SED_Initiatives;