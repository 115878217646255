// ContentBlock.js
import React from 'react';
import TextBlock from './TextBlock';
import ImageBlock from './ImageBlock';
import PollBlock from './PollBlock';
import VideoBlock from './VideoBlock';
import LinkBlock from './LinkBlock';
import FileBlock from './FileBlock';
import QuizBlock from './QuizBlock';
import CarouselBlock from './CarouselBlock';
import {
  Paper,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  IconButton,
  Box,
} from '@mui/material';
import { ArrowUpward, ArrowDownward, Delete } from '@mui/icons-material';

const contentBlockComponents = {
  Text: TextBlock,
  Image: ImageBlock,
  Poll: PollBlock,
  Video: VideoBlock,
  Link: LinkBlock,
  File: FileBlock,
  Quiz: QuizBlock,
  Carousel: CarouselBlock,
};

const ContentBlock = ({
  block,
  index,
  updateBlock,
  removeBlock,
  moveBlock,
  onFileChange,
  optionsByContentType = "POST",
}) => {
  const BlockComponent = contentBlockComponents[block.type];

  const allowedTypes = optionsByContentType === "HIGHLIGHT" ? ["Image", "Video"] : Object.keys(contentBlockComponents);


  const handleFileChange = (files) => {
    const blockIdentifier = block.PK || block.order.toString();
    onFileChange(blockIdentifier, files);
  };

  return (
    <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h6">Content Block {block.order}</Typography>
        <Box>
          <IconButton
            onClick={() => moveBlock(index, index - 1)}
            disabled={index === 0}
          >
            <ArrowUpward />
          </IconButton>
          <IconButton onClick={() => moveBlock(index, index + 1)}>
            <ArrowDownward />
          </IconButton>
          <IconButton onClick={() => removeBlock(index)}>
            <Delete />
          </IconButton>
        </Box>
      </Box>

      <FormControl fullWidth sx={{ mt: 2 }}>
        <InputLabel>Content Type</InputLabel>
        <Select
          value={block.type}
          label="Content Type"
          onChange={(e) =>
            updateBlock(index, { ...block, type: e.target.value })
          }
        >
          {allowedTypes.map((type) => (
            <MenuItem key={type} value={type}>
              {type}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <BlockComponent
        block={block}
        updateBlock={(updatedBlock) => updateBlock(index, updatedBlock)}
        onFileChange={handleFileChange}
      />
    </Paper>
  );
};

export default ContentBlock;
