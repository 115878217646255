import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getEventById, getEventRSVPStats, updateEvent } from '../services/smcEventService';

import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Paper,
  CircularProgress,
} from '@mui/material';

const EditSMCEvent = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [dateError, setDateError] = useState('');
  const [rsvpStats, setRsvpStats] = useState(null);
  
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    eventDate: '',
    eventTime: '',
  });

  useEffect(() => {
    fetchEventData();
  }, [id]);

  const fetchEventData = async () => {
    try {
      setLoading(true);
      const eventData = await getEventById(id);
      const statsData = await getEventRSVPStats(id);
      
      setRsvpStats(statsData);
      setFormData({
        title: eventData.eventName,
        description: eventData.description,
        eventDate: eventData.eventDate,
        eventTime: eventData.eventTime.substring(0, 5), // Get HH:mm part only
      });
    } catch (err) {
      setError('Failed to fetch event details. Please try again.');
      console.error('Fetch event error:', err);
    } finally {
      setLoading(false);
    }
  };

  const validateDate = (date) => {
    const selectedDate = new Date(date);
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Reset time part for date comparison

    if (selectedDate < today) {
      setDateError('Event date must be in the future');
      return false;
    }
    setDateError('');
    return true;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    
    if (name === 'eventDate') {
      validateDate(value);
    }

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateDate(formData.eventDate)) {
      return;
    }

    setLoading(true);
    setError('');

    try {
      await updateEvent(id, formData);
      navigate('/smc-events');
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to update event. Please try again.');
      console.error('Update event error:', err);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Container maxWidth="md" sx={{ textAlign: 'center', mt: 4 }}>
        <CircularProgress />
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="md" sx={{ textAlign: 'center', mt: 4 }}>
        <Typography color="error">{error}</Typography>
        <Button
          variant="contained"
          onClick={() => navigate('/smc-events')}
          sx={{ mt: 2 }}
        >
          Back to Events
        </Button>
      </Container>
    );
  }

  return (
    <Container maxWidth="md">
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" align="center" gutterBottom>
          Edit SMC Event
        </Typography>

        {rsvpStats && (
          <Paper sx={{ p: 2, mb: 3 }}>
            <Typography variant="h6" gutterBottom>
              RSVP Statistics
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Typography>
                Interested Users: {rsvpStats.interestedCount}
              </Typography>
            </Box>
          </Paper>
        )}

        <Paper sx={{ p: 3 }}>
          <form onSubmit={handleSubmit}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <TextField
                label="Event Name"
                name="title"
                value={formData.title}
                onChange={handleChange}
                required
                fullWidth
                placeholder="e.g., Annual Alumni Meet 2025"
              />

              <TextField
                label="Description"
                name="description"
                value={formData.description}
                onChange={handleChange}
                required
                fullWidth
                multiline
                rows={4}
                placeholder="Describe the event details, agenda, and any special instructions..."
              />

              <Box sx={{ display: 'flex', gap: 2 }}>
                <TextField
                  label="Event Date"
                  name="eventDate"
                  type="date"
                  value={formData.eventDate}
                  onChange={handleChange}
                  required
                  fullWidth
                  error={!!dateError}
                  helperText={dateError}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

                <TextField
                  label="Event Time"
                  name="eventTime"
                  type="time"
                  value={formData.eventTime}
                  onChange={handleChange}
                  required
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min steps
                  }}
                />
              </Box>

              {error && (
                <Typography color="error" align="center">
                  {error}
                </Typography>
              )}

              <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center' }}>
                <Button
                  type="button"
                  variant="outlined"
                  onClick={() => navigate('/smc-events')}
                  disabled={loading}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={loading}
                >
                  {loading ? <CircularProgress size={24} /> : 'Update Event'}
                </Button>
              </Box>
            </Box>
          </form>
        </Paper>
      </Box>
    </Container>
  );
};

export default EditSMCEvent;