import React from 'react';
import { 
  Grid, 
  Card, 
  CardContent, 
  Typography, 
  Box, 
  Skeleton,
  Tooltip,
  IconButton
} from '@mui/material';
import { 
  VolunteerActivism, 
  Psychology, 
  People,
  CalendarMonth,
  InfoOutlined
} from '@mui/icons-material';

const StatCard = ({ title, value, icon, subtitle, tooltip, loading }) => (
  <Card sx={{ height: '100%' }}>
    <CardContent>
      <Box display="flex" alignItems="center" mb={2}>
        {icon}
        <Typography variant="h6" component="div" ml={1} sx={{ flexGrow: 1 }}>
          {title}
        </Typography>
        {tooltip && (
          <Tooltip title={tooltip} arrow placement="top">
            <IconButton size="small" sx={{ ml: 1 }}>
              <InfoOutlined fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      {loading ? (
        <Skeleton variant="rectangular" height={40} />
      ) : (
        <>
          <Typography variant="h4" component="div" gutterBottom>
            {value}
          </Typography>
          {subtitle && (
            <Typography variant="body2" color="text.secondary">
              {subtitle}
            </Typography>
          )}
        </>
      )}
    </CardContent>
  </Card>
);

const StatsOverview = ({ stats, loading }) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={3}>
        <StatCard
          title="Expressed Interests"
          value={stats?.totalInterests || 0}
          icon={<VolunteerActivism color="primary" />}
          subtitle="From Get Involved form"
          tooltip="Number of users who have filled out the Express Interest form indicating their areas of interest"
          loading={loading}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <StatCard
          title="Logged Activities"
          value={stats?.totalActivities || 0}
          icon={<Psychology color="primary" />}
          subtitle="From My Engagements"
          tooltip="Total number of activities logged by alumni in the My Engagements form"
          loading={loading}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <StatCard
          title="Active Contributors"
          value={stats?.totalParticipants || 0}
          icon={<People color="primary" />}
          subtitle="Unique participants"
          tooltip="Number of unique alumni who have logged activities in My Engagements"
          loading={loading}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <StatCard
          title="Monthly Activities"
          value={`${stats?.averageActivitiesPerMonth?.toFixed(1) || '0'}`}
          icon={<CalendarMonth color="primary" />}
          subtitle="Average activities per month"
          tooltip="Average number of activities logged per month in the selected time period"
          loading={loading}
        />
      </Grid>
    </Grid>
  );
};

export default StatsOverview;