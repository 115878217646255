import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { highlightService } from "../services/highlightService";
import { useAuth } from "../contexts/AuthContext";
import ContentBlock from "../components/ContentBlocks/ContentBlock";
import { cleanPayload } from "../utils/payloadCleaner";

import {
  Box,
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  TextField,
} from "@mui/material";

const EditHighlight = () => {
  const { id } = useParams();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [files, setFiles] = useState({});
  const [highlightData, setHighlightData] = useState({
    userId: user.userId,
    contentBlocks: [],
    contentType: "HIGHLIGHT",
    targetAudience: `STATE#Karnataka`,
    visibilityLevel: "STATE",
    optionsByContentType: "HIGHLIGHT",
    expiryDate: null,
  });

  useEffect(() => {
    const fetchHighlight = async () => {
      try {
        const fetchedHighlight = await highlightService.getHighlightById(id);
        setHighlightData((prev) => ({
          ...prev,
          ...fetchedHighlight,
          contentBlocks: fetchedHighlight.contentBlocks || [],
          expiryDate: fetchedHighlight.expiryDate ? new Date(fetchedHighlight.expiryDate).toISOString().slice(0, 16) : null,
        }));
      } catch (err) {
        setError("Failed to fetch highlight");
        console.error("Fetch highlight error:", err);
      }
    };

    if (id) {
      fetchHighlight();
    }
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setHighlightData((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (blockIdentifier, newFiles) => {
    setFiles((prevFiles) => ({
      ...prevFiles,
      [blockIdentifier]: newFiles,
    }));
  };

  const updateContentBlock = (index, updatedBlock) => {
    setHighlightData((prev) => {
      const newBlocks = [...prev.contentBlocks];
      newBlocks[index] = updatedBlock;
      return { ...prev, contentBlocks: newBlocks };
    });
  };

  const removeContentBlock = (index) => {
    setHighlightData((prev) => ({
      ...prev,
      contentBlocks: prev.contentBlocks.filter((_, i) => i !== index),
    }));
  };

  const moveContentBlock = (fromIndex, toIndex) => {
    if (toIndex < 0 || toIndex >= highlightData.contentBlocks.length) return;
    setHighlightData((prev) => {
      const newBlocks = [...prev.contentBlocks];
      const [movedBlock] = newBlocks.splice(fromIndex, 1);
      newBlocks.splice(toIndex, 0, movedBlock);
      return { ...prev, contentBlocks: newBlocks };
    });
  };

  const addNewContentBlock = () => {
    setHighlightData((prev) => ({
      ...prev,
      contentBlocks: [
        ...prev.contentBlocks,
        {
          type: "Image",
          order: prev.contentBlocks.length + 1,
          imageContent: { url: "", caption: "" },
          createdDate: new Date().toISOString(),
          updatedDate: new Date().toISOString(),
          optionsByContentType: highlightData.optionsByContentType,
        },
      ],
    }));
  };

  const validateVideoBlocks = () => {
    const errors = [];
    highlightData.contentBlocks.forEach((block, index) => {
      if (block.type === 'Video') {
        if (!block.videoContent?.thumbnailUrl?.trim()) {
          errors.push(`Content Block ${index + 1}: Thumbnail URL is required for video content`);
        } else if (!block.videoContent.thumbnailUrl.match(/^https?:\/\/.+/)) {
          errors.push(`Content Block ${index + 1}: Invalid thumbnail URL format`);
        }
      }
    });
    return errors;
  };

  const hasValidationErrors = highlightData.contentBlocks.some(block => block.hasValidationError);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    if (highlightData.contentBlocks.length === 0) {
      setError("At least one content block is required.");
      return;
    }

    // Validate video thumbnails
    const validationErrors = validateVideoBlocks();
    if (validationErrors.length > 0) {
      setError(validationErrors.join('\n'));
      return;
    }

    // Check for validation errors in blocks
    if (hasValidationErrors) {
      setError("Please fix validation errors in content blocks before submitting");
      return;
    }

    const formData = new FormData();

    // Clean the payload and prepare submission data
    const submissionData = cleanPayload({
      ...highlightData,
      visibilityLevel: 'STATE',
      targetAudience: `STATE#Karnataka`,
      expiryDate: highlightData.expiryDate ? new Date(highlightData.expiryDate).toISOString() : null,
    });

    formData.append("metadata", JSON.stringify(submissionData));

    Object.entries(files).forEach(([blockIdentifier, blockFiles]) => {
      blockFiles.forEach((file, index) => {
        formData.append(
          `file`,
          file,
          `file_${blockIdentifier}_${index}_${file.name}`
        );
      });
    });

    try {
      await highlightService.updateHighlight(id, formData);
      navigate("/highlights");
    } catch (err) {
      setError("Failed to update highlight. Please try again.");
      console.error("Update highlight error:", err);
    }
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h4" align="center" sx={{ mt: 4, mb: 2 }}>
        Edit Highlight
      </Typography>
      {error && (
        <Typography color="error" align="center" sx={{ mb: 2, whiteSpace: 'pre-line' }}>
          {error}
        </Typography>
      )}
      <form onSubmit={handleSubmit}>
        <Box sx={{ mb: 2 }}>
          <FormControl fullWidth>
            <InputLabel>Content Type</InputLabel>
            <Select
              id="contentType"
              name="contentType"
              value={highlightData.contentType}
              label="Content Type"
              onChange={handleInputChange}
            >
              <MenuItem value="HIGHLIGHT">Highlight</MenuItem>
              <MenuItem value="EVENT">Event</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ mb: 2 }}>
          <TextField
            fullWidth
            label="Expiry Date"
            type="datetime-local"
            name="expiryDate"
            value={highlightData.expiryDate || ''}
            onChange={handleInputChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>
        <Box sx={{ mb: 2 }}>
          <Typography variant="h5" sx={{ mb: 2 }}>
            Content Blocks
          </Typography>
          {highlightData.contentBlocks.map((block, index) => (
            <ContentBlock
              key={index}
              block={block}
              index={index}
              updateBlock={updateContentBlock}
              removeBlock={removeContentBlock}
              moveBlock={moveContentBlock}
              onFileChange={handleFileChange}
              optionsByContentType={"HIGHLIGHT"}
            />
          ))}
          <Button
            variant="outlined"
            onClick={addNewContentBlock}
            sx={{ mt: 2 }}
          >
            Add New Content Block
          </Button>
        </Box>
        <Button 
          type="submit" 
          variant="contained" 
          color="primary" 
          fullWidth
          disabled={
            highlightData.contentBlocks.length === 0 ||
            hasValidationErrors
          }
        >
          Update Highlight
        </Button>
      </form>
    </Container>
  );
};

export default EditHighlight;