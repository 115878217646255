import { api } from './api';

const BASE_PATH = '/aluminiapp/v2/schools/udise';
const DEFAULT_UDISE = 'all';

export const ParticipationService = {
  // Interests endpoints
  getInterests: async (udiseCode = DEFAULT_UDISE, filters = {}) => {
    try {
      const response = await api.get(`${BASE_PATH}/${udiseCode}/participation/interests`, {
        params: filters
      });
      return response.data;
    } catch (error) {
      console.error('Get interests error:', error.response ? error.response.data : error.message);
      throw error;
    }
  },

  getInterestById: async (udiseCode = DEFAULT_UDISE, interestId) => {
    try {
      const response = await api.get(`${BASE_PATH}/${udiseCode}/participation/interests/${interestId}`);
      return response.data;
    } catch (error) {
      console.error('Get interest by id error:', error.response ? error.response.data : error.message);
      throw error;
    }
  },

  // Activities endpoints
  getActivities: async (udiseCode = DEFAULT_UDISE, filters = {}) => {
    try {
      const response = await api.get(`${BASE_PATH}/${udiseCode}/participation/activities`, {
        params: filters
      });
      return response.data;
    } catch (error) {
      console.error('Get activities error:', error.response ? error.response.data : error.message);
      throw error;
    }
  },

  getActivityById: async (udiseCode = DEFAULT_UDISE, activityId) => {
    try {
      const response = await api.get(`${BASE_PATH}/${udiseCode}/participation/activities/${activityId}`);
      return response.data;
    } catch (error) {
      console.error('Get activity by id error:', error.response ? error.response.data : error.message);
      throw error;
    }
  },

  // Stats and analytics
  getParticipationStats: async (udiseCode = DEFAULT_UDISE, filters = {}) => {
    try {
      const response = await api.get(`${BASE_PATH}/${udiseCode}/participation/stats`, {
        params: filters
      });
      return response.data;
    } catch (error) {
      console.error('Get participation stats error:', error.response ? error.response.data : error.message);
      throw error;
    }
  }
};

export const PARTICIPATION_TYPES = {
  VOLUNTEER: 'VOLUNTEER',
  SKILL_CONTRIBUTION: 'SKILL_CONTRIBUTION',
  DONATION: 'DONATION'
};

export const AVAILABILITY_TYPES = {
  WEEKDAYS: 'WEEKDAYS',
  WEEKENDS: 'WEEKENDS'
};

export const TIMING_TYPES = {
  MORNING: 'MORNING',
  AFTERNOON: 'AFTERNOON',
  EVENING: 'EVENING'
};

export const FREQUENCY_TYPES = {
  DAILY: 'DAILY',
  WEEKLY: 'WEEKLY',
  MONTHLY: 'MONTHLY',
  QUARTERLY: 'QUARTERLY',
  YEARLY: 'YEARLY'
};