// theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#5E35B1', // Deep purple color
      light: '#7E57C2',
      dark: '#4527A0',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#FF4081', // Pink color for accents
    },
    background: {
      default: '#F4F4F4',
      paper: '#FFFFFF',
    },
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
    h5: {
      fontWeight: 500,
    },
    button: {
      textTransform: 'none',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: 'none',
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: '#5E35B1', // Deep purple from the primary palette
            color: '#ffffff', // White text for the header
            fontSize: '16px', // Increase font size for better visibility
            fontWeight: 'bold', // Bold text for better emphasis
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
        },
      },
    },
  },
});

export default theme;
