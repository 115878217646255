import React from 'react';
import { Grid, Paper, Typography, Box, useTheme, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { 
  PostAdd, 
  Stars, 
  Event, 
  Groups, 
  People, 
  Chat 
} from '@mui/icons-material';

const ActionCard = ({ title, icon, description, linkTo }) => {
  const navigate = useNavigate();
  
  return (
    <Paper
      sx={{
        p: 2,
        height: '140px', // Fixed height for all cards
        cursor: 'pointer',
        transition: '0.3s',
        display: 'flex',
        flexDirection: 'column',
        '&:hover': { transform: 'translateY(-4px)', boxShadow: 3 }
      }}
      onClick={() => navigate(linkTo)}
    >
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        mb: 2 
      }}>
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center',
          justifyContent: 'center',
          mr: 1,
          '& > svg': { 
            fontSize: '28px',
            color: 'primary.main'
          }
        }}>
          {icon}
        </Box>
        <Typography variant="h6">
          {title}
        </Typography>
      </Box>
      <Typography 
        variant="body2" 
        color="text.secondary"
        sx={{
          flexGrow: 1,
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
      >
        {description}
      </Typography>
    </Paper>
  );
};

const Dashboard = () => {
  // TODO: Get actual user role from your auth context/state
  const userRole = 'ADMIN'; // This should come from your auth context

  // Quick check for permissions
  const canManageContent = ['ADMIN', 'CONTENT_CREATOR', 'SCHOOL_CONTENT_CREATOR'].includes(userRole);
  const canManageEvents = ['ADMIN', 'SCHOOL_EVENT_MANAGER', 'SMC_EVENT_MANAGER'].includes(userRole);
  const canManageUsers = ['ADMIN', 'USER_MANAGER'].includes(userRole);
  const canManageChats = ['ADMIN', 'GROUP_CHAT_MANAGER'].includes(userRole);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Typography 
        variant="h4" 
        gutterBottom 
        sx={{ 
          mb: 4,
          fontSize: { xs: '1.5rem', sm: '2rem' }
        }}
      >
        Alumni Admin Dashboard
      </Typography>

      <Grid container spacing={3}>
        {canManageContent && (
          <>
            <Grid item xs={12} sm={6} md={4}>
              <ActionCard 
                title="Create Post"
                icon={<PostAdd />}
                description="Create a new post or announcement"
                linkTo="/create-post"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <ActionCard 
                title="Create Highlight"
                icon={<Stars />}
                description="Add a new highlight"
                linkTo="/create-highlight"
              />
            </Grid>
          </>
        )}
        
        {canManageEvents && (
          <>
            <Grid item xs={12} sm={6} md={4}>
              <ActionCard 
                title="Create School Event"
                icon={<Event />}
                description="Schedule a new school event"
                linkTo="/create-school-event"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <ActionCard 
                title="Create SMC Event"
                icon={<Groups />}
                description="Schedule a new SMC event"
                linkTo="/create-smc-event"
              />
            </Grid>
          </>
        )}

        {canManageUsers && (
          <Grid item xs={12} sm={6} md={4}>
            <ActionCard 
              title="Manage Users"
              icon={<People />}
              description="View and manage user accounts"
              linkTo="/users"
            />
          </Grid>
        )}

        {canManageChats && (
          <Grid item xs={12} sm={6} md={4}>
            <ActionCard 
              title="Manage Chat Groups"
              icon={<Chat />}
              description="Manage chat groups and messages"
              linkTo="/chat-admin"
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default Dashboard;