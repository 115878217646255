import { api } from './api';

export const getAllDocuments = async (page = 0, limit = 10, type = null) => {
  try {
    const params = { page, limit };
    if (type) params.type = type;
    
    const response = await api.get('/aluminiapp/v2/gosCirculars', { params });
    return response.data;
  } catch (error) {
    console.error('Get documents error:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const createDocument = async (formData) => {
  try {
    const response = await api.post('/aluminiapp/v2/gosCirculars', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Create document error:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const updateDocument = async (id, formData) => {
  try {
    const response = await api.put(`/aluminiapp/v2/gosCirculars/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Update document error:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const deleteDocument = async (id) => {
  try {
    const response = await api.delete(`/aluminiapp/v2/gosCirculars/${id}`);
    return response.data;
  } catch (error) {
    console.error('Delete document error:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const getDocumentById = async (id) => {
  try {
    const response = await api.get(`/aluminiapp/v2/gosCirculars/${id}`);
    return response.data;
  } catch (error) {
    console.error('Get document error:', error.response ? error.response.data : error.message);
    throw error;
  }
};