import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getUser, updateUser } from "../services/userService";
import UserBasicInfo from "../components/users/UserBasicInfo";
import UserSchoolInfo from "../components/users/UserSchoolInfo";
import ProfilePictureUpload from "../components/users/ProfilePictureUpload";

const EditUser = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [profilePictureFile, setProfilePictureFile] = useState(null);
  const [profilePicturePreview, setProfilePicturePreview] = useState("");
  
  const [userData, setUserData] = useState({
    id: "",
    username: "",
    name: "",
    phoneNumber: "",
    whatsappNumber: "",
    email: "",
    dateOfBirth: "",
    gender: "",
    role: "",
    location: "",
    currentLocation: "",
    countryOfResidence: "",
    sector: "",
    profilePictureUrl: "",
    wallBackgroundUrl: "",
    schoolInfoList: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const fetchedData = await getUser(userId);

        if (fetchedData?.profilePictureUrl) {
          setProfilePicturePreview(fetchedData.profilePictureUrl);
        }

        const dateOnly = fetchedData?.dateOfBirth
          ? fetchedData.dateOfBirth.split("T")[0]
          : "";

        setUserData({
          id: fetchedData.id || "",
          username: fetchedData.username || "",
          name: fetchedData.name || "",
          phoneNumber: fetchedData.phoneNumber || "",
          whatsappNumber: fetchedData.whatsappNumber || "",
          email: fetchedData.email || "",
          dateOfBirth: dateOnly,
          gender: fetchedData.gender || "",
          role: fetchedData.role || "",
          location: fetchedData.location || "",
          currentLocation: fetchedData.currentLocation || "",
          countryOfResidence: fetchedData.countryOfResidence || "",
          sector: fetchedData.sector || "",
          profilePictureUrl: fetchedData.profilePictureUrl || "",
          wallBackgroundUrl: fetchedData.wallBackgroundUrl || "",
          schoolInfoList: fetchedData.schoolInfoList || [],
        });
      } catch (err) {
        console.error("Error fetching user:", err);
        setError("Failed to fetch user data. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [userId]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setProfilePictureFile(file);

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => setProfilePicturePreview(reader.result);
      reader.readAsDataURL(file);
    } else {
      setProfilePicturePreview("");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSchoolInfoChange = (index, e) => {
    const { name, value, type, checked } = e.target;
    setUserData((prev) => {
      const updatedSchools = [...prev.schoolInfoList];
      updatedSchools[index] = {
        ...updatedSchools[index],
        [name]: type === "checkbox" ? checked : value,
      };
      return { ...prev, schoolInfoList: updatedSchools };
    });
  };

  const handleAddSchool = () => {
    setUserData((prev) => ({
      ...prev,
      schoolInfoList: [
        ...prev.schoolInfoList,
        {
          udiseCode: "",
          name: "",
          blockName: "",
          district: "",
          classOfJoining: "",
          classOfPassing: "",
          favoriteTeacher: "",
          teacherContactDetails: "",
          isConnectedToSchool: false,
          willingToConnectWithSchool: false,
        },
      ],
    }));
  };

  const handleRemoveSchool = (index) => {
    setUserData((prev) => {
      const updatedSchools = [...prev.schoolInfoList];
      updatedSchools.splice(index, 1);
      return { ...prev, schoolInfoList: updatedSchools };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateUser(userId, userData, profilePictureFile);
      navigate("/users");
    } catch (err) {
      console.error("Error updating user:", err);
      setError("Failed to update user. Please try again.");
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div style={{ color: "red" }}>{error}</div>;

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-bold mb-6">Edit User</h2>
      <form onSubmit={handleSubmit} className="space-y-6">
        <ProfilePictureUpload
          preview={profilePicturePreview}
          onFileChange={handleFileChange}
        />

        <UserBasicInfo 
          userData={userData} 
          onChange={handleChange}
        />

        <UserSchoolInfo
          schools={userData.schoolInfoList}
          onSchoolChange={handleSchoolInfoChange}
          onAddSchool={handleAddSchool}
          onRemoveSchool={handleRemoveSchool}
        />

        <div className="mt-6">
          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          >
            Update Profile
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditUser;