import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Container,
  Paper,
  Typography,
  Grid,
  Box,
  Button,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { ParticipationService } from '../../services/participationService';

const ParticipationDetails = () => {
  const { id, type } = useParams(); // type will be either 'interests' or 'activities'
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchDetails();
  }, [id, type]);

  const fetchDetails = async () => {
    try {
      setLoading(true);
      let response;
      if (type === 'interests') {
        response = await ParticipationService.getInterestById(data?.udiseCode, id);
      } else {
        response = await ParticipationService.getActivityById(data?.udiseCode, id);
      }
      setData(response);
    } catch (error) {
      console.error('Error fetching details:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const renderMetadataItem = (label, value) => (
    <ListItem>
      <ListItemText
        primary={label}
        secondary={value || 'N/A'}
        primaryTypographyProps={{ variant: 'subtitle2', color: 'text.secondary' }}
        secondaryTypographyProps={{ variant: 'body1', color: 'text.primary' }}
      />
    </ListItem>
  );

  if (loading) {
    return <div>Loading...</div>; // Consider using a proper loading skeleton
  }

  if (error) {
    return <div>Error: {error}</div>; // Consider using a proper error component
  }

  return (
    <Container maxWidth="lg">
      <Box sx={{ py: 4 }}>
        <Button
          startIcon={<ArrowBack />}
          onClick={() => navigate(-1)}
          sx={{ mb: 3 }}
        >
          Back to Dashboard
        </Button>

        <Paper sx={{ p: 3 }}>
          <Grid container spacing={3}>
            {/* Header */}
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom>
                {type === 'interests' ? 'Interest Details' : 'Activity Details'}
              </Typography>
              <Chip
                label={data?.status}
                color={data?.status === 'ACTIVE' || data?.status === 'COMPLETED' ? 'success' : 'default'}
                sx={{ mt: 1 }}
              />
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            {/* Main Content */}
            <Grid item xs={12} md={6}>
              <Typography variant="h6" gutterBottom>
                Basic Information
              </Typography>
              <List>
                {renderMetadataItem('Type', data?.participationType)}
                {renderMetadataItem('Sub Type', data?.participationSubType)}
                {type === 'interests' && (
                  <>
                    {renderMetadataItem('Availability', data?.availabilityDay)}
                    {renderMetadataItem('Time Preference', data?.availabilityTime)}
                    {renderMetadataItem('Frequency', data?.participationFrequency)}
                  </>
                )}
                {type === 'activities' && (
                  <>
                    {renderMetadataItem('Activity Date', formatDate(data?.activityDate))}
                    {renderMetadataItem('Activity Details', data?.activityDetails)}
                  </>
                )}
              </List>
            </Grid>

            {/* Additional Metadata */}
            <Grid item xs={12} md={6}>
              <Typography variant="h6" gutterBottom>
                Additional Information
              </Typography>
              <List>
                {Object.entries(data?.metadata || {}).map(([key, value]) => (
                  renderMetadataItem(
                    key.replace(/([A-Z])/g, ' $1').trim(), // Convert camelCase to spaces
                    value
                  )
                ))}
              </List>
            </Grid>

            {/* Attachments Section */}
            {data?.attachments?.length > 0 && (
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Attachments
                </Typography>
                <List>
                  {data.attachments.map((attachment, index) => (
                    <ListItem key={index}>
                      <ListItemText
                        primary={attachment.name}
                        secondary={attachment.type}
                      />
                      <Button variant="outlined" href={attachment.url} target="_blank">
                        View
                      </Button>
                    </ListItem>
                  ))}
                </List>
              </Grid>
            )}
          </Grid>
        </Paper>
      </Box>
    </Container>
  );
};

export default ParticipationDetails;
