// TextBlock.js
import React, { useState, useEffect } from 'react';
import { TextField, Box } from '@mui/material';

const TextBlock = ({ block, updateBlock }) => {
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    // Validate initial state
    validateContent(block.textContent?.text || '');
  }, []);

  const validateContent = (text) => {
    const isValid = text.trim().length > 0;
    return isValid;
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setIsDirty(true);

    const isValid = validateContent(value);
    
    updateBlock({
      ...block,
      textContent: {
        ...block.textContent,
        text: value,
      },
      hasValidationError: !isValid
    });
  };

  return (
    <Box mt={2}>
      <TextField
        multiline
        rows={4}
        fullWidth
        label="Text Content"
        value={block.textContent?.text || ''}
        onChange={handleChange}
        error={isDirty && !validateContent(block.textContent?.text || '')}
        helperText={isDirty && !validateContent(block.textContent?.text || '') ? 'Text content cannot be empty' : ''}
        required
      />
    </Box>
  );
};

export default TextBlock;